export const allowedCountries = [
  {
    name: 'Australia',
    code: 'AU',
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Austria',
    code: 'AT',
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Belgium',
    code: 'BE',
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Canada',
    code: 'CA',
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Croatia',
    code: 'HR',
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Cyprus',
    code: 'CY',
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    emoji: '🇨🇿',
    unicode: 'U+1F1E8 U+1F1FF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Denmark',
    code: 'DK',
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Estonia',
    code: 'EE',
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Finland',
    code: 'FI',
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
    maxCompleteBy: 40
  },
  {
    name: 'France',
    code: 'FR',
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Germany',
    code: 'DE',
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Greece',
    code: 'GR',
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Hong Kong SAR China',
    code: 'HK',
    emoji: '🇭🇰',
    unicode: 'U+1F1ED U+1F1F0',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Hungary',
    code: 'HU',
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Ireland',
    code: 'IE',
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Italy',
    code: 'IT',
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Japan',
    code: 'JP',
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Latvia',
    code: 'LV',
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Lithuania',
    code: 'LT',
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Malta',
    code: 'MT',
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Mexico',
    code: 'MX',
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Netherlands',
    code: 'NL',
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
    maxCompleteBy: 40
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Norway',
    code: 'NO',
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Poland',
    code: 'PL',
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Portugal',
    code: 'PT',
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Romania',
    code: 'RO',
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Singapore',
    code: 'SG',
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Slovakia',
    code: 'SK',
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Slovenia',
    code: 'SI',
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Spain',
    code: 'ES',
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Sweden',
    code: 'SE',
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'Switzerland',
    code: 'CH',
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
    maxCompleteBy: 40
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    maxCompleteBy: 40
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
    maxCompleteBy: 40
  },
  {
    name: 'United States',
    code: 'US',
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
    maxCompleteBy: 40
  }
];
