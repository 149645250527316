import { useUser } from '@/context/userContext';
import { auth } from '@/firebase';

export const documentVerifiedLimit = 1000000;
export const mobileVerifiedLimit = 100000;
export const emailVerifiedLimit = 15000;

export const getUserLimits = () => {
  const { state: userState } = useUser();

  const isEmailVerified = auth.currentUser?.emailVerified;
  const isMobileVerified = userState.userAccount?.mobileVerified;
  const isDocumentVerified = userState.userAccount?.documentVerified;

  if (isDocumentVerified) {
    return documentVerifiedLimit;
  }

  if (isMobileVerified) {
    return mobileVerifiedLimit;
  }

  if (isEmailVerified) {
    return emailVerifiedLimit;
  }

  return 0;
};
