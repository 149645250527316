import { gql } from '@apollo/client';

export const PAYOUTS = gql`
  query Payouts {
    payouts {
      id
      status
      createdAt
      updatedAt
      transaction {
        id
        title
        status
        completeDays
        amount
        description
        createdAt
        updatedAt
      }
    }
  }
`;
