import { gql } from '@apollo/client';

export const CURRENT_USER_ACCOUNT = gql`
  query CurrentUserAccount {
    currentUserAccount {
      id
      userId
      systemRole
      email
      firstName
      lastName
      companyName
      mobileNumber
      stripeCustomerId
      countryCode
      createdAt
      updatedAt
      mobileVerified
      documentVerified
    }
  }
`;
