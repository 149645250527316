import { Header } from '@/components/App/Header';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { BadgeInfo, CircleHelp, Plus } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Input } from '@/components/ui/input';
import { CustomerSelect } from '@/components/App/Customer/CustomerSelect';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import bulletListIcon from '@/assets/bullet_list.svg';
import numberListIcon from '@/assets/number_list.svg';
import indentIncreaseIcon from '@/assets/indent_increase.svg';
import indentDecreaseIcon from '@/assets/indent_decrease.svg';
import boldIcon from '@/assets/bold.svg';
import italicIcon from '@/assets/italic.svg';
import underlineIcon from '@/assets/underline.svg';
import strikethroughIcon from '@/assets/strikethrough.svg';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_TRANSACTION } from '@/graphql/Transaction/CreateTransaction';
import { useNavigate } from 'react-router-dom';
import { TRANSACTIONS } from '@/graphql/Transaction/Transactions';
import { useUser } from '@/context/userContext';
import { SetupStripe } from '@/components/App/UserAccount/SetupStripe';
import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export const CreateTransaction = () => {
  const { state: userState } = useUser();
  const navigate = useNavigate();
  const [scopeEditorState, setScopeEditorState] = useState<EditorState>(EditorState.createEmpty());

  const { refetch: transactionsRefetch } = useQuery(TRANSACTIONS);
  const [createTransaction, { loading: creatingTransaction }] = useMutation(CREATE_TRANSACTION);

  const formSchema = z.object({
    title: z
      .string()
      .min(3, {
        message: 'Title must be at least 3 characters'
      })
      .max(80, {
        message: 'Title must be less than 80 characters'
      }),
    description: z.string().min(9, {
      message: 'Description is required'
    }),
    buyer: z.string().min(1, {
      message: 'Customer selection is required'
    }),
    amount: z
      .number()
      .min(1, {
        message: 'Amount must be at least 10'
      })
      .max(5000, {
        message: 'Amount must be less than 5000'
      }),
    completeDays: z
      .number()
      .min(1, {
        message: 'Days required must be at least 1'
      })
      .max(40, {
        message: 'Days required must be less than 40'
      }),
    scopeSupportingDocuments: z.any().optional(),
    requireVerification: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      requireVerification: false
    }
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    createTransaction({
      variables: {
        transaction: {
          buyer: values.buyer.split('--')[0],
          title: values.title,
          status: 'DRAFT',
          completeDays: values.completeDays,
          amount: values.amount * 100,
          description: values.description,
          scopeSupportingDocuments: []
        }
      }
    }).then((res) => {
      transactionsRefetch();
      navigate(`/app/transactions/${res.data.createTransaction.id}`);
    });
  };

  return (
    <>
      <Helmet>
        <title>Create Transaction | Gated Pay</title>
      </Helmet>
      <div className="main-div">
        {userState.stripeIsSetup ? (
          <div className="max-w-4xl w-full mx-auto space-y-6">
            <div className="flex gap-2 items-center justify-between">
              <Header title="Create Transaction" />
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off" className="space-y-5">
                <Card>
                  <CardContent className="py-5 space-y-5">
                    <FormField
                      control={form.control}
                      name="title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Create Figma designs for entire landing page"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className="grid grid-cols-3 gap-5">
                      <FormField
                        control={form.control}
                        name="buyer"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Customer</FormLabel>
                            <FormControl>
                              <CustomerSelect
                                value={field.value ?? ''}
                                setValue={(val: string) => field.onChange(val)}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="amount"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Amount</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                type="number"
                                placeholder="3500.00"
                                onChange={(e) => {
                                  field.onChange(Number(e.target.value));

                                  if (Number(e.target.value) >= 50) {
                                    form.setValue('requireVerification', true);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                            <FormField
                              control={form.control}
                              name="requireVerification"
                              render={({ field }) => (
                                <FormItem className="flex flex-row items-center space-x-2 space-y-0 pt-1 pl-1">
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value}
                                      onCheckedChange={(e) => {
                                        field.onChange(e);
                                      }}
                                    />
                                  </FormControl>

                                  <FormLabel className="flex items-center space-y-2 gap-2 text-secondary-foreground font-normal text-xs">
                                    Require ID verification ($2.00)
                                    <TooltipProvider delayDuration={50}>
                                      <Tooltip>
                                        <TooltipTrigger>
                                          <CircleHelp className="w-3.5 h-3.5 text-muted-foreground" />
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                          <div className="max-w-sm space-y-1">
                                            <div className="flex items-center gap-1 font-medium">
                                              <BadgeInfo className="w-4 h-4" />
                                              Recommendation
                                            </div>
                                            <div>
                                              We recommend you require ID verification for all
                                              transactions over $50.00 to protect you from
                                              chargebacks and disputes.
                                            </div>
                                          </div>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  </FormLabel>
                                </FormItem>
                              )}
                            />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="completeDays"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Days Required</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                placeholder="26"
                                type="number"
                                onChange={(e) => {
                                  field.onChange(Number(e.target.value));
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Editor
                              placeholder="Detailed description of goods/services"
                              toolbar={{
                                options: ['inline', 'list'],
                                inline: {
                                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                                  bold: { icon: boldIcon },
                                  italic: { icon: italicIcon },
                                  underline: { icon: underlineIcon },
                                  strikethrough: { icon: strikethroughIcon }
                                },
                                list: {
                                  unordered: { icon: bulletListIcon },
                                  ordered: { icon: numberListIcon },
                                  indent: { icon: indentIncreaseIcon },
                                  outdent: { icon: indentDecreaseIcon }
                                }
                              }}
                              editorState={scopeEditorState}
                              onEditorStateChange={setScopeEditorState}
                              onChange={(e) => field.onChange(draftToHtml(e))}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="scopeSupportingDocuments"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Supporting Documents</FormLabel>
                          <FormControl>
                            <Input type="file" {...field} multiple />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                <div className="flex gap-2 items-center justify-end">
                  <Button className="gap-1" type="submit" disabled={creatingTransaction}>
                    <Plus className="h-3.5 w-3.5" />
                    Create Transaction
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        ) : (
          <SetupStripe />
        )}
      </div>
    </>
  );
};
