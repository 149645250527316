import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '../../../ui/badge';
import { DataTableColumnHeader } from '../../../ui/data-table-column-header';
import { CalendarPlus, CircleDollarSign } from 'lucide-react';
import { formatDateTime, formatDollarFromCents } from '@/utils';
import { Payout } from './PayoutSchema';

export const payoutColumns: ColumnDef<Payout>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
    cell: ({ row }) => (
      <div className="w-[40px] uppercase font-medium">{row.original.id.substring(0, 5)}</div>
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    accessorKey: 'payout',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Transaction" />,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.transaction?.title}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      console.log(value);
      return (
        value.includes(row.original.status) ||
        value.includes(row.original.status?.replaceAll('_', ' ').toLowerCase()) ||
        row.original.transaction?.title?.toLowerCase().includes(value?.toLowerCase() ?? '')
      );
    }
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Amount" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          <CircleDollarSign className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{formatDollarFromCents(row.original.transaction?.amount)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => {
      return (
        <Badge variant="outline" className="capitalize">
          {row.original.status?.replaceAll('_', ' ').toLowerCase()}
        </Badge>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.status);
    }
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[200px] items-center">
          <CalendarPlus className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{formatDateTime(row.original.createdAt)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  }
];
