import { gql } from '@apollo/client';

export const SEND_MOBILE_VERIFICATION_CODE = gql`
  mutation SendMobileVerificationCode($mobileNumber: String!) {
    sendMobileVerificationCode(mobileNumber: $mobileNumber) {
      id
      userId
      systemRole
      email
      firstName
      lastName
      companyName
      mobileNumber
      stripeCustomerId
      countryCode
      createdAt
      updatedAt
      mobileVerified
      documentVerified
    }
  }
`;
