import { Loader2 } from 'lucide-react';

export const LoadingState = () => {
  return (
    <div className="flex flex-1 items-center justify-center h-full">
      <div className="flex items-center gap-1.5 text-center text-muted-foreground">
        <Loader2 className="animate-spin w-3.5 h-3.5" />
        Loading...
      </div>
    </div>
  );
};
