import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';
import { MobileNumberInput } from '@/components/ui/mobile-number-input';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2 } from 'lucide-react';
import { Logo } from '@/components/Common/Logo';
import { Checkbox } from '@/components/ui/checkbox';
import { CREATE_USER_ACCOUNT } from '@/graphql/UserAccount/CreateUserAccount';
import { useMutation } from '@apollo/client';
import { auth } from '@/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'sonner';
import { useState } from 'react';
import { SignupErrorCodes } from '@/enums/SignupErrorCodes';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { allowedCountries } from '@/assets/json/country_data';

export const Signup = () => {
  const navigate = useNavigate();
  const [creating, setCreating] = useState<boolean>(false);

  const [createUserAccount] = useMutation(CREATE_USER_ACCOUNT);

  const formSchema = z.object({
    email: z
      .string()
      .min(3, {
        message: 'Email must be at least 3 characters'
      })
      .max(80, {
        message: 'Email must be less than 80 characters'
      }),
    password: z
      .string()
      .min(3, {
        message: 'Password must be at least 8 characters'
      })
      .max(24, {
        message: 'Password must be less than 24 characters'
      }),
    firstName: z
      .string()
      .min(1, {
        message: 'First name must be at least 1 character'
      })
      .max(50, {
        message: 'First name must be less than 50 characters'
      }),
    lastName: z
      .string()
      .min(1, {
        message: 'Last name must be at least 1 character'
      })
      .max(50, {
        message: 'Last name must be less than 50 characters'
      }),
    company: z.string().max(100, {
      message: 'Company must be less than 100 characters'
    }),
    mobileNumber: z
      .string()
      .max(18, {
        message: 'Mobile number must be less than 18 characters'
      })
      .refine(isPossiblePhoneNumber, () => ({
        message: `Provide a valid phone number`
      })),
    country: z.string().min(2, {
      message: 'You must select a country'
    }),
    termsOfService: z.boolean().refine(
      (e) => !!e,
      () => ({
        message: `You must accept our terms of service`
      })
    )
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      country: 'US'
    }
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setCreating(true);
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((res) => {
        res.user.getIdToken().then((idToken) => {
          createUserAccount({
            variables: {
              userAccount: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                companyName: values.company,
                mobileNumber: values.mobileNumber,
                countryCode: values.country
              }
            },
            context: {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            }
          })
            .then(() => {
              navigate('/app');
            })
            .catch(() => {
              setCreating(false);
            });
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errorCode = error.code;

        switch (errorCode) {
          case SignupErrorCodes.EMAIL_IN_USE:
            toast.error('That email address is already in use, please sign in.');
            break;
          case SignupErrorCodes.INVALID_EMAIL:
            toast.error('The email address you provided is invalid.');
            break;
          case SignupErrorCodes.WEAK_PASSWORD:
            toast.error('The password you provided is too weak.');
            break;
          default:
            toast.error('There was a problem signing up, please try again.');
        }

        setCreating(false);
      });
  };

  return (
    <div className="h-screen w-screen flex overflow-y-auto p-24">
      <div className="max-w-5xl w-full mx-auto grid grid-cols-2 z-10">
        <div className="flex flex-col pr-20 gap-5 py-5">
          <Logo className="h-6 w-auto mb-5" />

          <div className="flex gap-3">
            <div className="pt-1">
              <CheckCircle2 className="w-4 h-4 text-primary" />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Secure transactions</div>
              <div className="text-sm text-gray-600">
                We guarantee the security of your transactions with top-notch online protection
              </div>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="pt-1">
              <CheckCircle2 className="w-4 h-4 text-primary" />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Simple process</div>
              <div className="text-sm text-gray-600">
                Our platform streamlines escrow, making it easy from start to finish
              </div>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="pt-1">
              <CheckCircle2 className="w-4 h-4 text-primary" />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Trusted and transparent mediation</div>
              <div className="text-sm text-gray-600">
                Our impartial team ensures fair dealings and resolves disputes swiftly with our
                open-source policy
              </div>
            </div>
          </div>

          <div className="flex gap-3">
            <div className="pt-1">
              <CheckCircle2 className="w-4 h-4 text-primary" />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Global coverage</div>
              <div className="text-sm text-gray-600">
                We cater to your needs worldwide, ensuring seamless cross-border transactions
              </div>
            </div>
          </div>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            <Card className="mx-auto w-full p-5">
              <CardHeader>
                <CardTitle className="text-xl">Create your Gated Pay account</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="max@gatedpay.com" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input {...field} type="password" placeholder="•••••••••" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-2 gap-5">
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First name</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Max" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last name</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Robinson" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="company"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Company</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Gated Pay, LLC." />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="mobileNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Mobile number</FormLabel>
                        <FormControl>
                          <MobileNumberInput {...field} placeholder="(950) 349-8103" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="country"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Country</FormLabel>
                        <FormControl>
                          <Select {...field} onValueChange={(e) => field.onChange(e)}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a country" />
                            </SelectTrigger>
                            <SelectContent>
                              {allowedCountries.map((countryData, index) => (
                                <SelectItem key={index} value={countryData.code}>
                                  <div className="flex items-center gap-1.5">
                                    <div>
                                      <img src={countryData.image} className="w-5 h-5" />
                                    </div>
                                    <div>{countryData.name}</div>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="termsOfService"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-2 space-y-0 my-1">
                        <FormControl>
                          <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                        </FormControl>
                        <FormLabel className="space-y-2 text-secondary-foreground font-normal">
                          By signing up, you accept our{' '}
                          <a className="underline">terms of service</a>
                          <FormMessage />
                        </FormLabel>
                      </FormItem>
                    )}
                  />

                  <Button type="submit" className="w-full" size="lg" disabled={creating}>
                    Create an account
                  </Button>
                </div>
                <div className="mt-4 text-center text-sm">
                  Already have an account?{' '}
                  <a className="underline cursor-pointer" onClick={() => navigate('/signin')}>
                    Sign in
                  </a>
                </div>
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>
    </div>
  );
};
