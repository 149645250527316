import { gql } from '@apollo/client';

export const TRANSACTION = gql`
  query Transaction($id: ID!) {
    transaction(id: $id) {
      id
      title
      status
      completeDays
      amount
      description
      completedOn
      confirmedCompletedOn
      scopeSupportingDocuments
      completionSupportingDocuments
      dispute {
        id
      }
      transactionPayment {
        id
        paymentStatus
        netPayment
        paymentId
        currency
        paymentAt
      }
      buyer {
        id
        firstName
        lastName
        companyName
        mobileNumber
        customerUserAccount {
          id
          email
        }
      }
      seller {
        id
        firstName
        lastName
        companyName
        email
        mobileNumber
      }
      transactionRole
      createdAt
      updatedAt
    }
  }
`;
