import {
  Home,
  Menu,
  Store,
  Users,
  BadgeDollarSign,
  CircleAlert,
  Landmark,
  ShieldX,
  ShieldCheck
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '@/context/userContext';

const NAVIGATION_ITEMS = [
  {
    label: 'Dashboard',
    href: '/app',
    icon: <Home className="h-5 w-5" />
  },
  {
    label: 'Customers',
    href: '/app/customers',
    icon: <Users className="h-5 w-5" />
  },
  {
    label: 'Transactions',
    href: '/app/transactions',
    icon: <BadgeDollarSign className="h-5 w-5" />
  },
  {
    label: 'Disputes',
    href: '/app/disputes',
    icon: <CircleAlert className="h-5 w-5" />
  },
  {
    label: 'Payouts',
    href: '/app/payouts',
    icon: <Landmark className="h-5 w-5" />
  }
];

interface LocationStateType {
  pathname: string;
}

const linkIsActive = (location: LocationStateType, link: string) => {
  const currentPath = location.pathname;
  const exactMatch = link === currentPath;
  const childMatch = link.split('/').length > 2 ? currentPath.includes(link) : false;

  return exactMatch || childMatch;
};

export const MobileNavigation = () => {
  const { state: userState } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-5 w-5" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col">
        <nav className="grid gap-2 text-lg font-medium">
          <div
            className="flex items-center gap-2 text-lg font-semibold"
            onClick={() => navigate('/app')}>
            <Store className="h-6 w-6" />
            <div className="flex flex-col">
              <div className="sr-only">{userState.userAccount?.companyName}</div>
              {!userState.stripeIsSetup && (
                <div className="text-red-500 text-xs flex items-center gap-0.5 font-normal">
                  <ShieldX className="w-3 h-3" />
                  Unverified
                </div>
              )}
            </div>
          </div>
          {NAVIGATION_ITEMS.map((navItem, index) => (
            <div
              key={index}
              className={`mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 hover:text-foreground ${
                linkIsActive(location, navItem.href)
                  ? 'text-foreground bg-muted'
                  : 'text-muted-foreground'
              }`}
              onClick={() => navigate(navItem.href)}>
              {navItem.icon}
              {navItem.label}
            </div>
          ))}
        </nav>
      </SheetContent>
    </Sheet>
  );
};

export const WebNavigation = () => {
  const { state: userState } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="hidden border-r bg-muted/40 md:block">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          {userState.userAccount?.companyName ? (
            <div
              className="flex items-center gap-2 font-semibold cursor-pointer"
              onClick={() => navigate('/app')}>
              <Store className="h-6 w-6" />
              <div className="flex flex-col">
                <div className="flex items-center gap-1">
                  <div>{userState.userAccount?.companyName}</div>
                  {userState.stripeIsSetup && <ShieldCheck className="w-4 h-4 text-green-500" />}
                </div>
                {!userState.stripeIsSetup && (
                  <div className="text-red-500 text-xs flex items-center gap-0.5 font-normal -mt-1">
                    <ShieldX className="w-3 h-3" />
                    Unverified
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="flex items-center gap-2 font-semibold cursor-pointer"
              onClick={() => navigate('/app')}>
              <Store className="h-6 w-6" />
              Gated Pay
            </div>
          )}
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            {NAVIGATION_ITEMS.map((navItem, index) => (
              <div
                key={index}
                className={`flex items-center gap-3 rounded-lg px-3 py-3 transition-all hover:text-primary cursor-pointer ${
                  linkIsActive(location, navItem.href)
                    ? 'text-primary bg-muted'
                    : 'text-muted-foreground'
                }`}
                onClick={() => navigate(navItem.href)}>
                {navItem.icon}
                {navItem.label}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
