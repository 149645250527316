import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useUser } from '@/context/userContext';
import { Separator } from '@/components/ui/separator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { allowedCountries } from '@/assets/json/country_data';
import { MobileNumberInput } from '@/components/ui/mobile-number-input';
import { UPDATE_USER_ACCOUNT } from '@/graphql/UserAccount/UpdateUserAccount';
import { useMutation } from '@apollo/client';

const accountFormSchema = z.object({
  firstName: z
    .string()
    .min(2, {
      message: 'First name must be at least 2 characters.'
    })
    .max(30, {
      message: 'First name must not be longer than 30 characters.'
    }),
  lastName: z
    .string()
    .min(2, {
      message: 'Last name must be at least 2 characters.'
    })
    .max(30, {
      message: 'Last name must not be longer than 30 characters.'
    }),
  mobileNumber: z
    .string()
    .max(18, {
      message: 'Mobile number must be less than 18 characters'
    })
    .refine(isPossiblePhoneNumber, () => ({
      message: `Provide a valid phone number`
    })),
  company: z.string().max(100, {
    message: 'Company must be less than 100 characters'
  }),
  country: z.string().min(2, {
    message: 'You must select a country'
  })
});

type AccountFormValues = z.infer<typeof accountFormSchema>;

export const AccountSettings = () => {
  const { state: userState, dispatch: userDispatch } = useUser();

  const [updateUserAccount, { loading: updateUserAccountLoading }] =
    useMutation(UPDATE_USER_ACCOUNT);

  const form = useForm<AccountFormValues>({
    resolver: zodResolver(accountFormSchema),
    defaultValues: {
      firstName: userState.userAccount?.firstName ?? '',
      lastName: userState.userAccount?.lastName ?? '',
      mobileNumber: userState.userAccount?.mobileNumber ?? '',
      company: userState.userAccount?.companyName ?? '',
      country: userState.userAccount?.countryCode ?? ''
    }
  });

  function onSubmit(data: AccountFormValues) {
    updateUserAccount({
      variables: {
        userAccount: {
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNumber: data.mobileNumber,
          companyName: data.company,
          countryCode: data.country
        }
      }
    }).then((res) => {
      userDispatch({
        type: 'SET_USER_ACCOUNT',
        userAccount: res.data.updateUserAccount
      });
    });
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Account</h3>
        <p className="text-sm text-muted-foreground">
          Update your account settings associated with your account.
        </p>
      </div>
      <Separator />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input
                placeholder="Your last name"
                disabled
                value={userState.userAccount?.email ?? ''}
              />
            </FormControl>
            <FormDescription>
              This is the email associated with your account. To change this, contact support.
            </FormDescription>
            <FormMessage />
          </FormItem>

          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input
                    disabled={userState.userAccount?.documentVerified}
                    placeholder="Your first name"
                    {...field}
                  />
                </FormControl>
                {userState.userAccount?.documentVerified && (
                  <FormDescription>
                    Your document has been verified, this cannot be changed.
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input
                    disabled={userState.userAccount?.documentVerified}
                    placeholder="Your last name"
                    {...field}
                  />
                </FormControl>
                {userState.userAccount?.documentVerified && (
                  <FormDescription>
                    Your document has been verified, this cannot be changed.
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="mobileNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Mobile number</FormLabel>
                <FormControl>
                  <MobileNumberInput
                    disabled={userState.userAccount?.mobileVerified}
                    {...field}
                    placeholder="(950) 349-8103"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="company"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company</FormLabel>
                <FormControl>
                  <Input {...field} disabled={userState.stripeIsSetup} />
                </FormControl>
                {userState.stripeIsSetup && (
                  <FormDescription>
                    Once your account is verified, this cannot be changed.
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    disabled={userState.stripeIsSetup}
                    onValueChange={(e) => field.onChange(e)}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a country" />
                    </SelectTrigger>
                    <SelectContent>
                      {allowedCountries.map((countryData, index) => (
                        <SelectItem key={index} value={countryData.code}>
                          <div className="flex items-center gap-1.5">
                            <div>
                              <img src={countryData.image} className="w-5 h-5" />
                            </div>
                            <div>{countryData.name}</div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                {userState.stripeIsSetup && (
                  <FormDescription>
                    Once your account is verified, this cannot be changed.
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            disabled={
              updateUserAccountLoading ||
              (userState.stripeIsSetup &&
                userState.userAccount?.documentVerified &&
                userState.userAccount.mobileVerified)
            }>
            Update account
          </Button>
        </form>
      </Form>
    </div>
  );
};
