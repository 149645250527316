import { Button, ButtonProps } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { useState } from 'react';
import { CREATE_CUSTOMER } from '@/graphql/Customers/CreateCustomer';
import { toast } from 'sonner';
import { MobileNumberInput } from '@/components/ui/mobile-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Customer } from '../Tables/Customers/CustomerSchema';

export interface CreateCustomerDialogProps {
  customersRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<unknown>>;
  triggerSlot: (props: ButtonProps) => JSX.Element;
  onCreate?: (customer: Customer) => void;
}

export const CreateCustomerDialog = ({
  customersRefetch,
  triggerSlot,
  onCreate
}: CreateCustomerDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [createCustomer, { loading: createCustomerLoading }] = useMutation(CREATE_CUSTOMER);

  const TriggerSlot = triggerSlot;

  const formSchema = z.object({
    email: z
      .string()
      .min(3, {
        message: 'Email must be at least 3 characters'
      })
      .max(80, {
        message: 'Email must be less than 80 characters'
      }),
    firstName: z
      .string()
      .min(1, {
        message: 'First name must be at least 1 character'
      })
      .max(50, {
        message: 'First name must be less than 50 characters'
      }),
    lastName: z
      .string()
      .min(1, {
        message: 'Last name must be at least 1 character'
      })
      .max(50, {
        message: 'Last name must be less than 50 characters'
      }),
    company: z
      .string()
      .max(100, {
        message: 'Company must be less than 100 characters'
      })
      .optional(),
    mobileNumber: z
      .string()
      .max(18, {
        message: 'Mobile number must be less than 18 characters'
      })
      .refine(isPossiblePhoneNumber, () => ({
        message: `Provide a valid phone number`
      }))
      .optional()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    createCustomer({
      variables: {
        customer: {
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.company ?? '',
          mobileNumber: values.mobileNumber ?? ''
        },
        email: values.email
      }
    })
      .then((res) => {
        onCreate?.(res.data.createCustomer);
        setOpen(false);

        customersRefetch();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);

        if (isOpen) {
          form.reset();
        }
      }}>
      <DialogTrigger asChild>
        <TriggerSlot onClick={() => setOpen(true)} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>Create customer</DialogTitle>
          <DialogDescription>
            Add a new customer to your account by filling in the following details.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form autoComplete="off">
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="max@gatedpay.com"
                        autoFocus
                        autoComplete="off"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Max" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Robinson" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="company"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Gated Pay, LLC." autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="mobileNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mobile number</FormLabel>
                    <FormControl>
                      <MobileNumberInput {...field} placeholder="(950) 349-8103" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button
                  type="button"
                  onClick={() => form.handleSubmit(onSubmit)()}
                  disabled={createCustomerLoading}>
                  Create customer
                </Button>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
