// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDyBC3yl5JYE1xU724-4mIM5veSID5HuKc',
  authDomain: 'gated-pay.firebaseapp.com',
  projectId: 'gated-pay',
  storageBucket: 'gated-pay.appspot.com',
  messagingSenderId: '661424382148',
  appId: '1:661424382148:web:06f0990d56b0951303a991',
  measurementId: 'G-4YCWW3B616'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
