import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function useDarkTheme() {
  const isDarkPreference = useMediaQuery({
    query: '(prefers-color-scheme: dark)'
  });

  const [theme, setTheme] = useState<string>(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') ?? 'dark'
      : isDarkPreference !== undefined
      ? isDarkPreference
        ? 'dark'
        : 'light'
      : 'light'
  );
  const colorTheme: string = theme === 'dark' ? 'light' : 'dark';

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);

    // save theme to local storage
    localStorage.setItem('theme', theme);
  }, [theme, colorTheme]);

  return [theme, setTheme];
}
