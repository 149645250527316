import { Table } from '@tanstack/react-table';

import { Button } from './button';
import { Input } from './input';
import { DataTableViewOptions } from './data-table-view-options';

import { statuses } from '../App/Tables/Payouts/PayoutData';
import { DataTableFacetedFilter } from './data-table-faceted-filter';
import { X } from 'lucide-react';
import { transactionStatuses, transactionTypes } from '../App/Tables/Transactions/TransactionData';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({ table }: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {table.getColumn('customer') && (
          <Input
            placeholder="Filter customers..."
            value={(table.getColumn('customer')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('customer')?.setFilterValue(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}

        {table.getColumn('payout') && (
          <Input
            placeholder="Filter payouts..."
            value={(table.getColumn('payout')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('payout')?.setFilterValue(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}
        {table.getColumn('payout') && (
          <DataTableFacetedFilter
            column={table.getColumn('status')}
            title="Payout Status"
            options={statuses}
          />
        )}

        {table.getColumn('transaction') && (
          <Input
            placeholder="Filter transaction..."
            value={(table.getColumn('transaction')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('transaction')?.setFilterValue(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}
        {table.getColumn('transaction') && (
          <DataTableFacetedFilter
            column={table.getColumn('status')}
            title="Transaction Status"
            options={transactionStatuses}
          />
        )}
        {table.getColumn('transactionRole') && (
          <DataTableFacetedFilter
            column={table.getColumn('transactionRole')}
            title="Transaction Role"
            options={transactionTypes}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3">
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
