import { gql } from '@apollo/client';

export const VERIFY_ONE_TIME_PASSWORD = gql`
  mutation VerifyOneTimePassword($otp: String!) {
    verifyOneTimePassword(otp: $otp) {
      id
      userId
      systemRole
      email
      firstName
      lastName
      companyName
      mobileNumber
      stripeCustomerId
      countryCode
      createdAt
      updatedAt
      mobileVerified
      documentVerified
    }
  }
`;
