import { LoadingState } from '@/components/App/LoadingState';
import { auth } from '@/firebase';
import { SETUP_STRIPE_ACCOUNT_URL } from '@/graphql/UserAccount/SetupStripeAccount';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export const StripeRedirection = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useQuery(SETUP_STRIPE_ACCOUNT_URL, {
    fetchPolicy: 'no-cache',
    skip: isLoading || auth.currentUser === null,
    onCompleted: (data) => {
      window.open(data.setupStripeAccount, '_self');
    }
  });

  useEffect(() => {
    auth.onAuthStateChanged(() => setIsLoading(false));
  }, [setIsLoading]);

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-background">
      <LoadingState />
    </div>
  );
};
