import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '../../../ui/badge';
import { DataTableColumnHeader } from '../../../ui/data-table-column-header';
import { CalendarPlus } from 'lucide-react';
import { formatDateTime } from '@/utils';
import { Customer } from './CustomerSchema';

export const customerColumns: ColumnDef<Customer>[] = [
  {
    accessorKey: 'customer',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" />,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.firstName} {row.original.lastName}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return (
        row.original.firstName?.toLowerCase()?.includes(value.toLowerCase()) ||
        row.original.lastName?.toLowerCase()?.includes(value.toLowerCase()) ||
        (row.original.companyName?.toLowerCase()?.includes(value.toLowerCase()) ?? false) ||
        (row.original.customerUserAccount?.email?.toLowerCase()?.includes(value.toLowerCase()) ??
          false)
      );
    }
  },
  {
    accessorKey: 'company',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Company" />,
    cell: ({ row }) => {
      return (
        <div className="max-w-[200px] items-center whitespace-nowrap overflow-hidden text-ellipsis">
          <span>{row.original.companyName ? row.original.companyName : '-'}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.companyName?.toLowerCase());
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row }) => {
      return <Badge variant="outline">{row.original.customerUserAccount?.email}</Badge>;
    }
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[200px] items-center">
          <CalendarPlus className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{formatDateTime(row.original.createdAt)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  }
];
