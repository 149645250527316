import { auth } from '@/firebase';
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ReactNode } from 'react';

interface AuthorizedApolloProviderProps {
  children: ReactNode | ReactNode[];
}

export const AuthorizedApolloProvider = ({ children }: AuthorizedApolloProviderProps) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
  });

  const authLink = setContext(async () => {
    const token = (await auth.currentUser?.getIdToken()) ?? '';
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(from([errorLink, httpLink])),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
