import { z } from 'zod';
import { userAccountSchema } from '../UserAccounts/UserAccountSchema';
import { customerSchema } from '../Customers/CustomerSchema';
import { transactionPaymentSchema } from './TransactionPaymentSchema';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const transactionSchema = z.object({
  id: z.string(),
  seller: userAccountSchema.optional(),
  buyer: customerSchema.optional(),
  title: z.string().optional(),
  status: z
    .enum([
      'PENDING_SCOPE_APPROVAL',
      'SCOPE_DENIED',
      'IN_PROGRESS',
      'PENDING_COMPLETION_CONFIRMATION',
      'CONFIRMED_COMPLETED',
      'UNDER_DISPUTE',
      'DRAFT',
      'COMPLETION_DENIED',
      'COMPLETED'
    ])
    .optional(),
  transactionRole: z.enum(['BUYER', 'SELLER']),
  completeDays: z.number().optional(),
  amount: z.number().optional(),
  description: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  completedOn: z.string().nullable().optional(),
  confirmedCompletedOn: z.string().nullable().optional(),
  scopeSupportingDocuments: z.any().optional(),
  completionSupportingDocuments: z.string().array().optional(),
  transactionPayment: transactionPaymentSchema.optional().nullable()
});

export type Transaction = z.infer<typeof transactionSchema>;
