import { gql } from '@apollo/client';

export const TRANSACTIONS = gql`
  query Transactions {
    transactions {
      id
      title
      status
      completeDays
      amount
      description
      buyer {
        id
        firstName
        lastName
        companyName
        customerUserAccount {
          id
          email
        }
      }
      seller {
        id
      }
      transactionRole
      createdAt
      updatedAt
    }
  }
`;
