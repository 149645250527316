import { gql } from '@apollo/client';

export const CUSTOMERS = gql`
  query Customers {
    customers {
      id
      customerUserAccount {
        id
        email
      }
      merchantUserAccount {
        id
      }
      createdAt
      updatedAt
      firstName
      lastName
      companyName
      mobileNumber
    }
  }
`;
