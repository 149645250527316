import { PayoutStatus } from '@/enums/PayoutStatus';
import { Ban, CircleCheck, CircleEllipsis, Undo } from 'lucide-react';

export const statuses = [
  {
    value: PayoutStatus.PENDING_PAYOUT,
    label: 'Pending Payout',
    icon: CircleEllipsis
  },
  {
    value: PayoutStatus.PAID,
    label: 'Paid',
    icon: CircleCheck
  },
  {
    value: PayoutStatus.DECLINED,
    label: 'Declined',
    icon: Ban
  },
  {
    value: PayoutStatus.REFUNDED,
    label: 'Reversed',
    icon: Undo
  }
];
