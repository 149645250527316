import { Logo } from '../../Common/Logo';

export interface FooterChildItem {
  title: string;
  path: string;
}

export interface FooterItem {
  title: string;
  children: FooterChildItem[];
}

export const Footer = () => {
  const items: FooterItem[] = [
    {
      title: 'Resources',
      children: [
        { title: 'How It Works', path: '' },
        { title: 'Who We Help', path: '' },
        { title: 'Pricing', path: '' },
        { title: 'Dispute Process', path: '' }
      ]
    },
    {
      title: 'Earn',
      children: [
        { title: 'Affiliate Program', path: '' },
        { title: 'Referral Program', path: '' },
        { title: 'Partner Benefits', path: '' },
        { title: 'Partner Enquiry', path: '' }
      ]
    },
    {
      title: 'About',
      children: [
        { title: 'Security', path: '' },
        { title: 'Fees', path: '' },
        { title: 'Terms of Use', path: '' },
        { title: 'Privacy Policy', path: '' }
      ]
    },
    {
      title: 'Support',
      children: [
        { title: 'Help Center', path: '' },
        { title: 'Contact Support', path: '' },
        { title: 'Complaints & Disputes', path: '' },
        { title: 'Request a Feature', path: '' }
      ]
    }
  ];

  return (
    <div className="pt-16 bg-white text-gray-900">
      <div className="mx-auto max-w-6xl">
        <div className="grid grid-cols-5 gap-10 font-medium">
          <div className="space-y-3">
            <Logo className="h-6 w-auto !text-white" />

            <div className="text-sm text-gray-500">
              Protecting consumers and merchants one payment at a time.
            </div>

            <div>
              <img
                src="https://s3-alpha.figma.com/hub/file/1659619545/e1f08393-359b-440d-8eba-75ac5c83168b-cover.png"
                className="h-auto w-full"
              />
            </div>
          </div>

          {items.map((itemList: FooterItem, index: number) => (
            <div key={index} className="flex flex-col gap-1">
              <div className="uppercase text-primary font-medium text-sm pb-1">
                {itemList.title}
              </div>
              {itemList.children.map((child: FooterChildItem) => (
                <button key={child.title} className="text-left font-light">
                  {child.title}
                </button>
              ))}
            </div>
          ))}
        </div>

        <div className="mx-auto max-w-6xl text-center pt-16 pb-4 text-xs font-medium text-gray-500">
          Copyright &copy; {new Date().getFullYear()} Tradely Apps Pty Ltd trading as Gated Pay |
          All Rights Reserved
        </div>
      </div>
    </div>
  );
};
