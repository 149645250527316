import { EmptyState } from '@/components/App/EmptyState';
import { Header } from '@/components/App/Header';
import { DataTable } from '@/components/ui/data-table';
import { MoveRight } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { payoutColumns } from '@/components/App/Tables/Payouts/PayoutColumns';
import { z } from 'zod';
import { useQuery } from '@apollo/client';
import { PAYOUTS } from '@/graphql/Payouts/Payouts';
import { LoadingState } from '@/components/App/LoadingState';
import { payoutSchema } from '@/components/App/Tables/Payouts/PayoutSchema';

export const Payouts = () => {
  const { loading: payoutsLoading, data: payoutsData } = useQuery(PAYOUTS);

  return (
    <>
      <Helmet>
        <title>Payouts | Gated Pay</title>
      </Helmet>
      <div className="main-div">
        <Header title="Payouts" />

        {payoutsLoading ? (
          <LoadingState />
        ) : payoutsData?.payouts?.length === 0 ? (
          <EmptyState
            title="You have no payouts"
            subtitle="Once a transaction is complete, your payouts will be listed here."
            buttonIcon={<MoveRight className="ml-2 w-4 h-4" />}
            buttonText="Go to Transactions"
            buttonRoute="/app/transactions"
          />
        ) : (
          payoutsData?.payouts && (
            <DataTable
              data={z.array(payoutSchema).parse(payoutsData.payouts)}
              columns={payoutColumns}
            />
          )
        )}
      </div>
    </>
  );
};
