import { Button } from '@/components/ui/button';
import { useUser } from '@/context/userContext';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { auth } from '@/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'sonner';
import { useState } from 'react';
import { ShieldCheck } from 'lucide-react';
import { formatDollarFromCents } from '@/utils';
import { emailVerifiedLimit } from '@/utils/UserLimits';

export const EmailVerification = () => {
  const { state: userState } = useUser();
  const [loading, setLoading] = useState<boolean>(false);

  const verifyEmail = () => {
    setLoading(true);

    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser).then(() => {
        toast(`Verification email sent to ${userState.userAccount?.email}`);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-base flex items-center gap-2 font-medium">
          {auth.currentUser?.emailVerified ? (
            <Badge variant="success" className="gap-0.5">
              <ShieldCheck className="w-3 h-3 -ml-0.5" />
              Verified
            </Badge>
          ) : (
            <Badge variant="destructive">Pending</Badge>
          )}
          Email Address
          <div className="flex-1"></div>
          <Badge variant="outline">
            <div className="flex items-center gap-1">
              <div className="font-normal">Increases limit to</div>
              {formatDollarFromCents(emailVerifiedLimit, 'usd', false)}
            </div>
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="flex items-center gap-3">
          <Input disabled value={userState.userAccount?.email} />
          {!userState.userAccount?.documentVerified && (
            <Button
              disabled={auth.currentUser?.emailVerified || loading}
              onClick={() => verifyEmail()}>
              Verify
            </Button>
          )}
        </div>

        <p className="text-sm text-muted-foreground">
          We use your email address for verification and notification purposes only. We will never
          share your email address with third parties.
        </p>
      </CardContent>
    </Card>
  );
};
