import { UserAccount } from '@/components/App/Tables/UserAccounts/UserAccountSchema';
import { createContext, useContext, useReducer } from 'react';

type UserAction =
  | {
      type: 'SET_USER_ACCOUNT';
      userAccount: UserAccount;
    }
  | {
      type: 'SET_STRIPE_STATUS';
      isSetup: boolean;
    };

type UserState = {
  userAccount: UserAccount | undefined;
  stripeIsSetup: boolean;
  loading: boolean;
};

type UserDispatch = (action: UserAction) => void;
type UserProviderProps = { children: React.ReactNode };

const UserContext = createContext<{ state: UserState; dispatch: UserDispatch } | undefined>(
  undefined
);

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'SET_USER_ACCOUNT': {
      if (action.userAccount) {
        return {
          ...state,
          userAccount: action.userAccount,
          loading: false
        };
      } else {
        throw new Error(`No payload specified for action type: ${action.type}`);
      }
    }
    case 'SET_STRIPE_STATUS': {
      return {
        ...state,
        stripeIsSetup: action.isSetup
      };
    }
  }
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [state, dispatch] = useReducer(userReducer, {
    userAccount: undefined,
    stripeIsSetup: false,
    loading: true
  });

  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
