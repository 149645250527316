import { gql } from '@apollo/client';

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($customer: CustomerCreateInput!, $email: String!) {
    createCustomer(customer: $customer, email: $email) {
      id
      firstName
      lastName
      customerUserAccount {
        email
      }
    }
  }
`;
