import Typewriter from 'typewriter-effect';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Hero = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');

  return (
    <div className="bg-primary">
      <div className="sm:py-18 py-16 md:py-24 lg:py-24 pt-8 md:pt-16 overflow-hidden text-white">
        <div className="mx-auto max-w-6xl">
          <div className="flex items-center justify-between">
            <div className="flex flex-col justify-center max-w-lg w-full">
              <div className="font-bold text-5xl">Buy & Sell</div>
              <div className="font-bold text-6xl">
                <Typewriter
                  options={{
                    strings: ['Services', 'Virtual Goods', 'Physical Goods'],
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 20
                  }}
                />
              </div>

              <div className="mt-8 text-xl">
                The easiest way to buy and sell virtual and physical goods and services online.
              </div>

              <div className="mt-8">
                <div className="flex max-w-md w-full items-center bg-white rounded-md">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email address"
                    className="flex-1 h-14 p-3 text-black focus:outline-none bg-white rounded-md"
                  />
                  <button
                    className="h-12 mr-1 px-8 bg-primary hover:bg-primary/80 transition ease-in-out text-lg rounded-md"
                    onClick={() => navigate(`/signup/${email}`)}>
                    Get started
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg w-full max-w-md flex items-center justify-center p-5 min-h-[30rem]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
