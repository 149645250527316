import { Button } from '@/components/ui/button';
import { useUser } from '@/context/userContext';
import { MobileNumberInput } from '@/components/ui/mobile-number-input';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Badge } from '@/components/ui/badge';
import { SEND_MOBILE_VERIFICATION_CODE } from '@/graphql/UserAccount/SendMobileVerificationCode';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';
import { VERIFY_ONE_TIME_PASSWORD } from '@/graphql/UserAccount/VerifyOneTimePassword';
import { ShieldCheck } from 'lucide-react';
import { mobileVerifiedLimit } from '@/utils/UserLimits';
import { formatDollarFromCents } from '@/utils';

export const MobileVerification = () => {
  const { state: userState, dispatch: userDispatch } = useUser();
  const [mobileNumber, setMobileNumber] = useState<string>(
    userState.userAccount?.mobileNumber ?? ''
  );
  const [enterOtp, setEnterOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');

  const [sendMobileVerification, { loading: sendMobileVerificationLoading }] = useMutation(
    SEND_MOBILE_VERIFICATION_CODE
  );
  const [verifyOtp, { loading: verifyOtpLoading }] = useMutation(VERIFY_ONE_TIME_PASSWORD);

  const clickSendMobileVerification = () => {
    sendMobileVerification({
      variables: {
        mobileNumber: mobileNumber
      }
    }).then(() => setEnterOtp(true));
  };

  const clickSendOtp = () => {
    verifyOtp({
      variables: {
        otp: otp
      }
    }).then((res) => {
      console.log(res);
      const newUserAccount = res.data.verifyOneTimePassword;

      userDispatch({
        type: 'SET_USER_ACCOUNT',
        userAccount: newUserAccount
      });

      if (newUserAccount.mobileVerified) {
        setEnterOtp(false);
      }
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-base flex items-center gap-2 font-medium">
          {userState.userAccount?.mobileVerified ? (
            <Badge variant="success" className="gap-0.5">
              <ShieldCheck className="w-3 h-3 -ml-0.5" />
              Verified
            </Badge>
          ) : (
            <Badge variant="destructive">Pending</Badge>
          )}
          Mobile Number
          <div className="flex-1"></div>
          <Badge variant="outline">
            <div className="flex items-center gap-1">
              <div className="font-normal">Increases limit to</div>
              {formatDollarFromCents(mobileVerifiedLimit, 'usd', false)}
            </div>
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {enterOtp ? (
          <div className="flex flex-col items-center gap-3">
            <Label>Enter your one-time password:</Label>
            <InputOTP maxLength={6} value={otp} onChange={(value: string) => setOtp(value)}>
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            <div className="flex items-center gap-3">
              <Button
                disabled={userState.userAccount?.mobileVerified || sendMobileVerificationLoading}
                variant="outline">
                Resend
              </Button>
              <Button
                disabled={userState.userAccount?.mobileVerified || verifyOtpLoading}
                onClick={() => clickSendOtp()}>
                Verify
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <MobileNumberInput
              disabled={userState.userAccount?.mobileVerified}
              value={mobileNumber}
              onChange={(e: string) => setMobileNumber(e)}
            />
            {!userState.userAccount?.documentVerified && (
              <Button
                disabled={userState.userAccount?.mobileVerified || sendMobileVerificationLoading}
                onClick={() => clickSendMobileVerification()}>
                Verify
              </Button>
            )}
          </div>
        )}
        <p className="text-sm text-muted-foreground">
          We use your mobile number for verification purposes only. We will never share your mobile
          number with third parties or send you spam messages.
        </p>
      </CardContent>
    </Card>
  );
};
