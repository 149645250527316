import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Edit } from 'lucide-react';
import { Customer } from '../Tables/Customers/CustomerSchema';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER } from '@/graphql/Customers/UpdateCustomer';
import { useState } from 'react';
import { MobileNumberInput } from '@/components/ui/mobile-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export interface EditCustomerDialogProps {
  customer?: Customer;
  customerRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<unknown>>;
}

export const EditCustomerDialog = ({ customer, customerRefetch }: EditCustomerDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const formSchema = z.object({
    firstName: z
      .string()
      .min(1, {
        message: 'First name must be at least 1 character'
      })
      .max(50, {
        message: 'First name must be less than 50 characters'
      }),
    lastName: z
      .string()
      .min(1, {
        message: 'Last name must be at least 1 character'
      })
      .max(50, {
        message: 'Last name must be less than 50 characters'
      }),
    company: z
      .string()
      .max(100, {
        message: 'Company must be less than 100 characters'
      })
      .optional(),
    mobileNumber: z
      .string()
      .max(18, {
        message: 'Mobile number must be less than 18 characters'
      })
      .refine(isPossiblePhoneNumber, () => ({
        message: `Provide a valid phone number`
      }))
      .optional()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    updateCustomer({
      variables: {
        customer: {
          id: customer?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.company ?? '',
          mobileNumber: values.mobileNumber ?? ''
        }
      }
    }).then(() => customerRefetch().then(() => setOpen(false)));
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        if (isOpen) {
          form.reset();
          form.setValue('firstName', customer?.firstName ?? '');
          form.setValue('lastName', customer?.lastName ?? '');
          form.setValue('company', customer?.companyName ?? '');
          form.setValue('mobileNumber', customer?.mobileNumber ?? '');
        }
      }}>
      <DialogTrigger asChild>
        <Button size="icon" variant="outline" className="h-8 w-8" onClick={() => setOpen(true)}>
          <Edit className="h-3.5 w-3.5" />
          <span className="sr-only">Edit</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit customer details</DialogTitle>
          <DialogDescription>
            Make changes to your customer details here. Click save when you&apos;re done.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Max" autoFocus autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Robinson" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="company"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Gated Pay, LLC." autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="mobileNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mobile number</FormLabel>
                    <FormControl>
                      <MobileNumberInput {...field} placeholder="(950) 349-8103" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button type="submit">Save changes</Button>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
