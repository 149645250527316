import { Logo } from '@/components/Common/Logo';
import { Button } from '@/components/ui/button';
import { useLocation, useNavigate } from 'react-router-dom';

export interface NavItemProps {
  label: string;
  href: string;
  active?: boolean;
  external?: boolean;
}

export const Navigation = () => {
  const NAV_ITEMS: Array<NavItemProps> = [
    {
      label: 'Home',
      href: '/'
    },
    {
      label: 'How it works',
      href: '/how-it-works'
    },
    {
      label: 'Who we help',
      href: '/who-we-help'
    },
    {
      label: 'Pricing',
      href: '/pricing'
    },
    {
      label: 'Documentation',
      href: 'https://docs.gatedpay.com/',
      external: true
    },
    {
      label: 'Support',
      href: '/support'
    }
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const linkIsActive = (link: string) => {
    const currentPath = location.pathname;
    const exactMatch = link === currentPath;
    const childMatch = link.split('/').length > 2 ? currentPath.includes(link) : false;

    return exactMatch || childMatch;
  };

  return (
    <div className="bg-primary">
      <div className="mx-auto max-w-6xl">
        <div className="flex justify-between items-center py-4">
          <div>
            <Logo className="h-6 w-auto !text-white" />
          </div>
          <div className="flex items-center justify-end gap-7">
            {NAV_ITEMS.map((navItem) => (
              <button
                key={navItem.label}
                className={`text-white uppercase text-xs font-medium tracking-wider border-b-2 py-2 transition ease-in-out cursor-pointer ${
                  linkIsActive(navItem.href)
                    ? 'border-white'
                    : 'border-transparent hover:border-white hover:border-opacity-50'
                }`}
                onClick={() =>
                  navItem.external ? window.open(navItem.href) : navigate(navItem.href)
                }>
                {navItem.label}
              </button>
            ))}

            <div className="space-x-2">
              <Button
                className="uppercase text-xs "
                size="sm"
                variant="navigation-white"
                onClick={() => navigate('/signup')}>
                Sign up
              </Button>
              <Button
                className="uppercase text-xs"
                size="sm"
                variant="navigation-white-border"
                onClick={() => navigate('/signin')}>
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
