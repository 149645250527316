import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { Footer } from '@/components/Landing/Common/Footer';
import { Navigation } from '@/components/Landing/Common/Navigation';
import { JoinOthers } from '@/components/Landing/Common/JoinOthers';
import { PricingHero } from '@/components/Landing/Pricing/PricingHero';

export const Pricing = () => {
  return (
    <div>
      <Navigation />

      <PricingHero />

      <div className="bg-white py-16">
        <div className="mx-auto max-w-6xl">
          <div className="text-center w-full text-3xl font-semibold">
            Frequently asked questions
          </div>

          <Disclosure>
            {({ open }) => (
              <div className="border-b">
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  How long do payouts take?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    Payout speed varies per country, but your Gated Pay balance is typically
                    available for payout between 2 to 7 business days.
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <div className="border-b">
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  How do refunds work?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    You can issue either partial or full refunds. Depending on your fee schedule
                    with Gated Pay, there may be some fees associated with issuing refunds. The
                    payment processing and currency conversion fees if applicable from the original
                    charge are not returned.
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  How do disputes work?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    Disputes are typically resolved without the need for mediation. In the rare case
                    that the merchant and customer cannot come to an agreeance, a trained dispute
                    specialist will step in and help resolve the dispute.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <JoinOthers />

      <Footer />
    </div>
  );
};
