import { SETUP_STRIPE_ACCOUNT_URL } from '@/graphql/UserAccount/SetupStripeAccount';
import { Header } from '../Header';
import { useQuery } from '@apollo/client';
import { Button } from '@/components/ui/button';
import { useUser } from '@/context/userContext';
import { ShieldX } from 'lucide-react';

export const SetupStripe = () => {
  const { state: userState } = useUser();

  const { loading: stripeSetupUrlLoading, data: stripeSetupUrlData } = useQuery(
    SETUP_STRIPE_ACCOUNT_URL,
    {
      fetchPolicy: 'no-cache'
    }
  );

  return (
    <div className="max-w-xl w-full mx-auto space-y-6 flex flex-col">
      <div className="flex gap-2 items-center">
        <Header title="Setup Stripe for payments" />
      </div>
      <div>
        You have not yet verified{' '}
        <div className="inline-flex items-center gap-0.5 text-red-500 ">
          <ShieldX className="w-3 h-3" />
          {userState.userAccount?.companyName}
        </div>{' '}
        as a business with Stripe. In order to start accepting payments, you must verify your
        business with Stripe.
      </div>
      <div className="stripe-description">
        We use Stripe to make sure you get paid on time and to keep your personal bank and details
        secure. Click <strong>Continue to setup</strong> to set up your payments on Stripe.
      </div>

      {!stripeSetupUrlLoading && (
        <Button
          className="w-full"
          onClick={() => window.open(stripeSetupUrlData.setupStripeAccount, '_self')}>
          Continue to setup
        </Button>
      )}
    </div>
  );
};
