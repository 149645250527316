import * as React from 'react';

import { cn } from '@/lib/utils';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MobileNumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MobileNumberInput = React.forwardRef<HTMLInputElement, any>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}>
        <PhoneInput {...props} defaultCountry="US" />
      </div>
    );
  }
);
MobileNumberInput.displayName = 'MobileNumberInput';

export { MobileNumberInput };
