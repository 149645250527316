import { screens } from 'tailwindcss/defaultTheme';
import moment from 'moment';
import { toast } from 'react-toastify';

export const getBreakpointSize = (breakpoint) => {
  const breakpointSize = screens[breakpoint];
  const matches = /(\d+).*/.exec(breakpointSize);
  if (matches) {
    return parseInt(matches[1], 10);
  }
  throw new Error(`Invalid breakpoint: ${breakpoint}`);
};
/**
 * Returns one week in milliseconds
 */
export const oneWeek = () => new Date().getTime() - 1000 * 60 * 60 * 24 * 7;

export const isValidEmail = (emailAddress) => EMAIL_REGEX.test(emailAddress);

// Source: https://emailregex.com/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidUrl = (url) => URL_REGEX.test(url);

// Source: https://regexr.com/39nr7
export const URL_REGEX =
  /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;

export const isLink = (url) => {
  return url.match(/^(http|https):\/\/[^ "]+$/);
};

export const DATE_TIME_FORMAT = 'yyyy-MM-dd-HH-mm-ss.SSS';

export const US_DATE_TIME_FORMAT = 'MM-dd-yyyy HH:mm';

export const isBlank = (str) => !str || str.trim().length === 0;

export const validateLinkedInProfileLink = (profileLink) => {
  const profilePrefix = 'https://linkedin.com/in/';
  if (!profileLink.startsWith(profilePrefix)) {
    return `Profile URL must start with '${profilePrefix}'.`;
  }
  if (profileLink.length <= profilePrefix.length) {
    return 'Invalid Profile URL: missing user name.';
  }
};

export const validateLinkedInLink = (link) => {
  const linkRegex = /^https:\/\/(www\.)?linkedin.com\/.*/;
  if (!link.match(linkRegex)) {
    return `Invalid LinkedIn URL.`;
  }
};

export const isInvalidDomain = (link) => {
  const linkRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  return !link.match(linkRegex);
};

export const classNames = (...args) => args.filter((arg) => arg).join(' ');

/**
 * Securely opens the given URL in a new tab.
 * For security details, see:
 * https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
 */
export const openInNewTab = (url) => {
  const newTab = window.open(url, '_blank', 'noopener,noreferrer');
  if (newTab) {
    newTab.opener = null;
  }
};

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export const formatDollarFromCents = (cents, currency = 'usd', symbol = true) => {
  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: currency,
    currencyDisplay: symbol ? 'symbol' : 'narrowSymbol'
  });

  return formatter.format(cents / 100);
};

export const formatDateTime = (dateTime) => {
  return moment(dateTime).format('D MMM YYYY, h:mm A');
};

export const formatDate = (date) => {
  return moment(date).format('D MMMM, YYYY');
};

export const toastErrorWithMessage = (id, message) => {
  toast.error(message, {
    toastId: id
  });
};

export const focusNextElement = () => {
  //add all elements we want to include in our selection
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  if (document.activeElement && document.activeElement.form) {
    const focussable = Array.prototype.filter.call(
      document.activeElement.form.querySelectorAll(focussableElements),
      function (element) {
        //check for visibility while always include the current activeElement
        return (
          element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
        );
      }
    );
    const index = focussable.indexOf(document.activeElement);
    if (index > -1) {
      const nextElement = focussable[index + 1] || focussable[0];
      nextElement.focus();
    }
  }
};

export const sortFunction = (a, b) => {
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const sortDateFunction = (a, b) => {
  if (a.isBefore(b)) {
    return 1;
  }

  if (b.isBefore(a)) {
    return -1;
  }

  return 0;
};
