import { z } from 'zod';
import { transactionSchema } from '../Transactions/TransactionSchema';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const payoutSchema = z.object({
  id: z.string(),
  transaction: transactionSchema.optional(),
  status: z.enum(['PENDING_PAYOUT', 'PAID', 'DECLINED', 'REFUNDED']).optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});

export type Payout = z.infer<typeof payoutSchema>;
