import { Hero } from '@/components/Landing/Home/Hero';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { Footer } from '@/components/Landing/Common/Footer';
import { Navigation } from '@/components/Landing/Common/Navigation';
import { JoinOthers } from '@/components/Landing/Common/JoinOthers';

export const Home = () => {
  return (
    <div className="h-screen overflow-auto">
      <Navigation />

      <Hero />

      <div className="py-16 bg-blue-50 text-primary">
        <div className="mx-auto max-w-6xl">
          <div className="grid grid-cols-4 items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <div className="text-5xl font-bold">120k+</div>
              <div className="tracking-wider">Global Customers</div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="text-5xl font-bold">195+</div>
              <div className="tracking-wider">Customer Countries</div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="text-5xl font-bold">$600m+</div>
              <div className="tracking-wider">Processed Payments</div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="text-5xl font-bold">1%</div>
              <div className="tracking-wider">Lowest Fee</div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 bg-white text-gray-900">
        <div className="mx-auto max-w-5xl text-center">
          <div className="text-3xl font-medium">
            The{' '}
            <span className="bg-gradient-to-r from-cyan-500 to-primary text-transparent bg-clip-text">
              safest
            </span>{' '}
            way to start accepting payments
          </div>

          <div className="max-w-3xl mx-auto w-full my-7 text-gray-500">
            With ease of use at the core of our approach, we have constructed an interface with
            usability in mind, quick-to-access features and information that you actually want to
            see.
          </div>

          <div className="flex items-start justify-center gap-14">
            <div className="flex flex-col items-center justify-center gap-2 max-w-xs w-full">
              <div className="py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="url(#grad1)"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-10 h-10">
                  <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop
                        offset="0%"
                        style={{ stopColor: 'rgb(6 182 212 / 1)', stopOpacity: 1 }}></stop>
                      <stop offset="100%" style={{ stopColor: '#0077db', stopOpacity: 1 }}></stop>
                    </linearGradient>
                  </defs>
                  <path
                    fillRule="evenodd"
                    d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                    clipRule="evenodd"></path>
                </svg>
              </div>
              <div className="text-xl font-medium">Create an account</div>
              <div className="text-gray-500">
                Sign up and verify with our easy process and you&apos;re good to go!
              </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 max-w-xs w-full">
              <div className="py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="url(#grad1)"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-10 h-10">
                  <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop
                        offset="0%"
                        style={{ stopColor: 'rgb(6 182 212 / 1)', stopOpacity: 1 }}></stop>
                      <stop offset="100%" style={{ stopColor: '#0077db', stopOpacity: 1 }}></stop>
                    </linearGradient>
                  </defs>
                  <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"></path>
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"></path>
                  <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z"></path>
                </svg>
              </div>
              <div className="text-xl font-medium">Accept funds</div>
              <div className="text-gray-500">
                Create a milestone and accept payment from your customer
              </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 max-w-xs w-full">
              <div className="py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="url(#grad1)"
                  aria-hidden="true"
                  data-slot="icon"
                  className="w-10 h-10">
                  <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop
                        offset="0%"
                        style={{ stopColor: 'rgb(6 182 212 / 1)', stopOpacity: 1 }}></stop>
                      <stop offset="100%" style={{ stopColor: '#0077db', stopOpacity: 1 }}></stop>
                    </linearGradient>
                  </defs>
                  <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z"></path>
                  <path
                    fillRule="evenodd"
                    d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z"
                    clipRule="evenodd"></path>
                  <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                </svg>
              </div>
              <div className="text-xl font-medium">Get paid to your bank</div>
              <div className="text-gray-500">
                Get paid straight to your bank account in your local currency
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 bg-blue-50 text-gray-900">
        <div className="mx-auto max-w-5xl flex flex-col items-center justify-center gap-20">
          <div className="grid grid-cols-2 gap-40 items-center justify-center w-full">
            <div className="bg-white rounded-lg w-full h-96"></div>

            <div className="flex flex-col gap-6">
              <div className="text-4xl font-semibold">
                Support for{' '}
                <span className="bg-gradient-to-r from-cyan-500 to-primary text-transparent bg-clip-text">
                  420+ cryptocurrencies
                </span>
              </div>

              <div className="text-lg text-gray-500">
                We offer a variety of cryptocurrency to buy, sell, or swap. Get started in minutes
                and start trading your cryptocurrencies!
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-40 items-center justify-center w-full">
            <div className="flex flex-col gap-6">
              <div className="text-4xl font-semibold">
                Get help within{' '}
                <span className="bg-gradient-to-r from-cyan-500 to-primary text-transparent bg-clip-text">
                  minutes
                </span>
              </div>

              <div className="text-lg text-gray-500">
                CoinSpot&apos;s locally based and knowledgeable Support Team is here for you. Both,
                our Live Chat and Help Desk are available 24/7 to speak to real people everyday.
              </div>
            </div>

            <div className="">
              <img src="https://www.coinspot.com.au/public/img/chat-blue.png?v=368" />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-16 bg-primary text-white">
        <div className="mx-auto max-w-5xl">
          <div className="grid grid-cols-2 gap-40 items-center justify-center w-full">
            <div className="flex flex-col gap-6">
              <div className="text-4xl font-semibold">Buy and sell Bitcoin on-the-go</div>

              <div className="text-lg font-medium">
                Download the CoinSpot app and manage your investments everywhere you go.
              </div>
            </div>

            <div>
              <img src="https://www.coinspot.com.au/public/img/home-app-2.png?v=368" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white text-gray-900 py-16">
        <div className="mx-auto max-w-5xl">
          <div className="text-center w-full text-3xl font-semibold">
            Frequently asked questions
          </div>

          <Disclosure>
            {({ open }) => (
              <div className="border-b">
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  Why should I choose CoinSpot?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    CoinSpot is the largest, most established exchange in Australia since 2013.
                    CoinSpot offers Australia’s largest variety of digital assets with over 420
                    coins listed where users can buy, sell & swap benefiting from the lowest fees
                    starting from 0.1%. Trade with peace of mind knowing that CoinSpot has the
                    highest level of globally recognised security certification in Australia. Our
                    friendly Support Team provides premium customer service where users can directly
                    engage with real people 24/7 to assist with any queries.
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <div className="border-b">
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  What do I need to get started?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    Anyone over the age of 16 can open an account with CoinSpot; get started with an
                    email address, Australian mobile number & a valid form of ID to verify your
                    account.
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="py-5 w-full text-left font-semibold text-xl flex items-center justify-between">
                  How do I buy crypto?
                  <ChevronRightIcon
                    className={`w-6 h-6 transition ease-in-out text-brand ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel className="text-gray-500 pb-5" static>
                    Buying crypto has never been more convenient. Once account verification has been
                    completed, users are able to choose from a range of free and instant AUD deposit
                    methods such as PayID, & Direct deposit to allocate funds to their account. Once
                    the funds have arrived, head to the buy/sell page where you can instantly
                    purchase any of the assets listed.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <JoinOthers />

      <Footer />
    </div>
  );
};
