export enum TransactionStatus {
  PENDING_SCOPE_APPROVAL = 'PENDING_SCOPE_APPROVAL',
  SCOPE_DENIED = 'SCOPE_DENIED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_COMPLETION_CONFIRMATION = 'PENDING_COMPLETION_CONFIRMATION',
  CONFIRMED_COMPLETED = 'CONFIRMED_COMPLETED',
  UNDER_DISPUTE = 'UNDER_DISPUTE',
  DRAFT = 'DRAFT',
  COMPLETION_DENIED = 'COMPLETION_DENIED',
  COMPLETED = 'COMPLETED'
}
