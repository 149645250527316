import { z } from 'zod';
import { transactionSchema } from '../Transactions/TransactionSchema';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const disputeSchema = z.object({
  id: z.string(),
  transaction: transactionSchema.optional(),
  status: z
    .enum([
      'OPEN',
      'WAITING_FOR_MERCHANT',
      'UNDER_REVIEW',
      'CLOSED_IN_FAVOR_OF_CUSTOMER',
      'CLOSED_IN_FAVOR_OF_MERCHANT'
    ])
    .optional(),
  refundAmount: z.number().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});

export type Dispute = z.infer<typeof disputeSchema>;
