import { Separator } from '@/components/ui/separator';
import { MobileVerification } from '@/components/App/UserAccount/Verification/MobileVerification';
import { EmailVerification } from '@/components/App/UserAccount/Verification/EmailVerification';
import { DocumentVerification } from '@/components/App/UserAccount/Verification/DocumentVerification';
import { Badge } from '@/components/ui/badge';
import { getUserLimits } from '@/utils/UserLimits';
import { formatDollarFromCents } from '@/utils';

export const VerificationSettings = () => {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-medium">Verification</h3>
            <p className="text-sm text-muted-foreground">
              Go through verification to increase limit per transaction.
            </p>
          </div>

          <Badge variant="outline" className="py-2 !px-4">
            <div className="flex items-center gap-1 text-sm">
              <div className="font-normal">Limit:</div>
              {formatDollarFromCents(getUserLimits(), 'usd', false)}
            </div>
          </Badge>
        </div>
      </div>
      <Separator />
      <EmailVerification />
      <MobileVerification />
      <DocumentVerification />
    </div>
  );
};
