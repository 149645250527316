import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

export const PricingHero = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-primary">
      <div className="sm:py-18 py-16 md:py-24 lg:py-24 pt-8 md:pt-16 overflow-hidden">
        <div className="mx-auto max-w-6xl">
          <div className="flex items-center justify-center text-5xl text-white font-bold pb-14">
            Pricing built for businesses of all sizes
          </div>

          <div className="grid grid-cols-2 gap-8 items-center justify-center">
            <div className="grid grid-cols-2 items-center gap-2 p-1 bg-white rounded-lg">
              <div className="flex flex-col justify-center px-5 py-8 gap-2">
                <div className="font-semibold text-2xl">Standard</div>
                <div className="text-gray-500">
                  Access a complete payments platform with simple, pay-as-you-go pricing. No setup
                  fees, monthly fees, or hidden fees.
                </div>
                <div className="mt-3">
                  <Button onClick={() => navigate('/signup')}>Get Started</Button>
                </div>
              </div>

              <div className="flex flex-col gap-1 items-center justify-center bg-gray-100 h-full rounded-lg">
                <div className="font-bold text-3xl">$0.30 + 2.75%</div>
                <div>for all payment types</div>
              </div>
            </div>

            <div className="grid grid-cols-2 items-center gap-2 p-1 bg-white/10 rounded-lg">
              <div className="flex flex-col justify-center px-5 py-8 gap-2">
                <div className="font-semibold text-2xl text-white">Custom</div>
                <div className="text-white/80">
                  Design a custom package – available for businesses with large payments volume or
                  unique business models.
                </div>
                <div className="mt-3">
                  {/* TODO */}
                  <Button
                    variant="secondary"
                    onClick={() =>
                      window.open('mailto:sales@gatedpay.com?subject=Custom pricing enquiry')
                    }>
                    Contact Sales
                  </Button>
                </div>
              </div>

              <div className="flex flex-col gap-1 items-center justify-center rounded-lg h-full w-full text-white font-medium">
                <div className="text-center bg-white/20 flex items-center justify-center w-full h-full rounded-lg">
                  IC+ pricing
                </div>
                <div className="text-center bg-white/20 flex items-center justify-center w-full h-full rounded-lg">
                  Volume discounts
                </div>
                <div className="text-center bg-white/20 flex items-center justify-center w-full h-full rounded-lg">
                  Multi-product discounts
                </div>
                <div className="text-center bg-white/20 flex items-center justify-center w-full h-full rounded-lg">
                  Country-specific rates
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
