import { useRoutes } from 'react-router-dom';
import { HomeLayout } from './layouts/HomeLayout';
import { Home } from './pages/Landing/Home';
import { Dashboard } from './pages/App/Dashboard';
import useDarkTheme from './hooks/useDarkTheme';
import { StripeRedirection } from './pages/App/StripeRedirection';
import { Pricing } from './pages/Landing/Pricing';
import { AppLayout } from './layouts/AppLayout';
import { Payouts } from './pages/App/Payouts';
import { Transactions } from './pages/App/Transactions';
import { Customers } from './pages/App/Customers';
import { Disputes } from './pages/App/Disputes';
import { Customer } from './pages/App/Customer';
import { Transaction } from './pages/App/Transaction';
import { CreateTransaction } from './pages/App/CreateTransaction';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { ResetPassword } from './pages/ResetPassword';
import { SettingsLayout } from './layouts/SettingsLayout';
import { AccountSettings } from './pages/App/Settings/AccountSettings';
import { AppearanceSettings } from './pages/App/Settings/AppearanceSettings';
import { NotificationSettings } from './pages/App/Settings/NotificationSettings';
import { VerificationSettings } from './pages/App/Settings/VerificationSettings';

export const App = () => {
  useDarkTheme();

  return useRoutes([
    {
      path: '/',
      element: <HomeLayout />,
      children: [
        {
          index: true,
          element: <Home />
        },
        {
          path: '/pricing',
          element: <Pricing />
        }
      ]
    },
    {
      path: '/signin',
      element: <Login />
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/app',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: '/app/customers',
          element: <Customers />
        },
        {
          path: '/app/customers/:id',
          element: <Customer />
        },
        {
          path: '/app/transactions',
          element: <Transactions />
        },
        {
          path: '/app/transactions/:id',
          element: <Transaction />
        },
        {
          path: '/app/create-transaction',
          element: <CreateTransaction />
        },
        {
          path: '/app/create-transaction/:customerId',
          element: <Dashboard />
        },
        {
          path: '/app/disputes',
          element: <Disputes />
        },
        {
          path: '/app/disputes/:disputeId',
          element: <Dashboard />
        },
        {
          path: '/app/payouts',
          element: <Payouts />
        },
        {
          path: '/app/settings',
          element: <SettingsLayout />,
          children: [
            {
              index: true,
              element: <AccountSettings />
            },
            {
              path: '/app/settings/appearance',
              element: <AppearanceSettings />
            },
            {
              path: '/app/settings/notifications',
              element: <NotificationSettings />
            },
            {
              path: '/app/settings/verification',
              element: <VerificationSettings />
            }
          ]
        }
      ]
    },
    {
      path: '/app/stripe-reauth/:userAccountId',
      element: <StripeRedirection />
    }
  ]);
};
