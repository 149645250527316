import { LoadingState } from '@/components/App/LoadingState';
import { Logo } from '@/components/Common/Logo';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { auth } from '@/firebase';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

export const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        navigate('/app');
      } else {
        setIsLoading(false);
      }
    });
  });

  const formSchema = z.object({
    email: z
      .string()
      .min(3, {
        message: 'Email must be at least 3 characters'
      })
      .max(80, {
        message: 'Email must be less than 80 characters'
      }),
    password: z
      .string()
      .min(3, {
        message: 'Password must be at least 8 characters'
      })
      .max(24, {
        message: 'Password must be less than 24 characters'
      })
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      navigate('/app');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e.code);
      if (e.code === 'auth/invalid-credential') {
        toast.error('Your email or password was incorrect.');
      } else if (e.code === 'auth/invalid-email') {
        toast.error('Please provide a valid email address.');
      } else if (e.code === 'auth/missing-password') {
        toast.error('Please provide a password.');
      } else {
        toast.error('There was a problem signing in with those credentials, please try again.');
      }
    }
  };

  return isLoading ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingState />
    </div>
  ) : (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full max-w-md space-y-8">
        <Logo className="h-6 w-auto ml-5" />

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            <Card className="mx-auto p-8">
              <CardHeader>
                <CardTitle className="text-xl">Sign in to your account</CardTitle>
                <CardDescription></CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mx-auto grid gap-6 w-full">
                  <div className="grid gap-5">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="max@gatedpay.com" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <div className="flex items-center">
                              <Label htmlFor="password">Password</Label>
                              <a
                                className="ml-auto inline-block text-sm underline text-primary font-base cursor-pointer"
                                onClick={() => navigate('/reset-password')}>
                                Forgot your password?
                              </a>
                            </div>
                          </FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="•••••••••" type="password" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button type="submit" className="w-full">
                      Login
                    </Button>
                  </div>
                  <div className="mt-4 text-center text-sm">
                    Don&apos;t have an account?{' '}
                    <a className="underline cursor-pointer" onClick={() => navigate('/signup')}>
                      Sign up
                    </a>
                  </div>
                </div>
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>
    </div>
  );
};
