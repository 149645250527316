import { gql } from '@apollo/client';

export const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUserAccount($userAccount: UserAccountUpdateInput!) {
    updateUserAccount(userAccount: $userAccount) {
      id
      userId
      systemRole
      email
      firstName
      lastName
      companyName
      mobileNumber
      stripeCustomerId
      countryCode
      createdAt
      updatedAt
      mobileVerified
      documentVerified
    }
  }
`;
