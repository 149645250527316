import { Button } from '@/components/ui/button';
import { useUser } from '@/context/userContext';
import { useLazyQuery } from '@apollo/client';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { toast } from 'sonner';
import { VERIFICATION_SESSION } from '@/graphql/UserAccount/VerificationSession';
import { loadStripe } from '@stripe/stripe-js';
import { Loader2, ShieldCheck } from 'lucide-react';
import { CURRENT_USER_ACCOUNT } from '@/graphql/UserAccount/CurrentUserAccount';
import { useState } from 'react';
import { documentVerifiedLimit } from '@/utils/UserLimits';
import { formatDollarFromCents } from '@/utils';

const stripePromise = loadStripe('pk_test_L1f0e3XAzjsG7jtp4uN7L9ql');

export const DocumentVerification = () => {
  const { state: userState, dispatch: userDispatch } = useUser();
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const [fetchUserAccount] = useLazyQuery(CURRENT_USER_ACCOUNT, {
    fetchPolicy: 'no-cache'
  });

  const [startVerificationSession, { loading }] = useLazyQuery(VERIFICATION_SESSION, {
    fetchPolicy: 'no-cache'
  });

  const handleClick = async () => {
    const stripe = await stripePromise;

    if (!stripe) {
      // Stripe.js hasn't loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    setIsVerifying(true);

    // Call your backend to create the VerificationSession.
    const res = await startVerificationSession();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(res.data.verificationSession);

    if (error) {
      toast.error('There was a problem verifying your document/s. Please try again.');
      setIsVerifying(false);
    } else {
      const userAccountRes = await fetchUserAccount();
      userDispatch({
        type: 'SET_USER_ACCOUNT',
        userAccount: userAccountRes.data.currentUserAccount
      });

      setIsVerifying(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-base flex items-center gap-2 font-medium">
          {userState.userAccount?.documentVerified ? (
            <Badge variant="success" className="gap-0.5">
              <ShieldCheck className="w-3 h-3 -ml-0.5" />
              Verified
            </Badge>
          ) : (
            <Badge variant="destructive">Pending</Badge>
          )}
          Document
          <div className="flex-1"></div>
          <Badge variant="outline">
            <div className="flex items-center gap-1">
              <div className="font-normal">Increases limit to</div>
              {formatDollarFromCents(documentVerifiedLimit, 'usd', false)}
            </div>
          </Badge>
          {!userState.userAccount?.documentVerified && (
            <Button disabled={loading || isVerifying} onClick={() => handleClick()}>
              {isVerifying ? (
                <div className="flex items-center gap-1.5 text-center text-white">
                  <Loader2 className="animate-spin w-3.5 h-3.5" />
                  Verifying...
                </div>
              ) : (
                'Verify Document'
              )}
            </Button>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="stripe-description !mb-0">
          We use Stripe to verify your document/s. We do not process or access your documents
          uploaded to Stripe, they are used solely for verification purposes.
        </div>
      </CardContent>
    </Card>
  );
};
