import { EmptyState } from '@/components/App/EmptyState';
import { Header } from '@/components/App/Header';
import { DataTable } from '@/components/ui/data-table';
import { MoveRight, Plus } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { z } from 'zod';
import { useQuery } from '@apollo/client';
import { LoadingState } from '@/components/App/LoadingState';
import { transactionSchema } from '@/components/App/Tables/Transactions/TransactionSchema';
import { transactionColumns } from '@/components/App/Tables/Transactions/TransactionColumns';
import { TRANSACTIONS } from '@/graphql/Transaction/Transactions';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';

export const Transactions = () => {
  const navigate = useNavigate();
  const { loading: transactionsLoading, data: transactionsData } = useQuery(TRANSACTIONS);

  return (
    <>
      <Helmet>
        <title>Transactions | Gated Pay</title>
      </Helmet>
      <div className="main-div">
        <div className="flex gap-2 items-center justify-between">
          <Header title="Transactions" />
          <Button className="gap-1" onClick={() => navigate('/app/create-transaction')}>
            <Plus className="h-3.5 w-3.5" />
            Create Transaction
          </Button>
        </div>

        {transactionsLoading ? (
          <LoadingState />
        ) : transactionsData?.transactions?.length === 0 ? (
          <EmptyState
            title="You have no transactions"
            subtitle="Once you create a transaction, it will be displayed here."
            buttonIcon={<MoveRight className="ml-2 w-4 h-4" />}
            buttonText="Create Transaction"
            buttonRoute="/app/create-transaction"
          />
        ) : (
          transactionsData?.transactions && (
            <DataTable
              data={z.array(transactionSchema).parse(transactionsData.transactions)}
              columns={transactionColumns}
              onRowSelect={(row) => {
                navigate(`/app/transactions/${row.original.id}`);
              }}
            />
          )
        )}
      </div>
    </>
  );
};
