import { EmptyState } from '@/components/App/EmptyState';
import { Header } from '@/components/App/Header';
import { DataTable } from '@/components/ui/data-table';
import { MoveRight, Plus } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { customerColumns } from '@/components/App/Tables/Customers/CustomerColumns';
import { z } from 'zod';
import { useQuery } from '@apollo/client';
import { CUSTOMERS } from '@/graphql/Customers/Customers';
import { LoadingState } from '@/components/App/LoadingState';
import { customerSchema } from '@/components/App/Tables/Customers/CustomerSchema';
import { useNavigate } from 'react-router-dom';
import { CreateCustomerDialog } from '@/components/App/Customer/CreateCustomerDialog';
import { Button, ButtonProps } from '@/components/ui/button';

export const Customers = () => {
  const navigate = useNavigate();
  const {
    loading: customersLoading,
    data: customersData,
    refetch: customersRefetch
  } = useQuery(CUSTOMERS);

  return (
    <>
      <Helmet>
        <title>Customers | Gated Pay</title>
      </Helmet>
      <div className="main-div">
        <div className="flex gap-2 items-center justify-between">
          <Header title="Customers" />
          <CreateCustomerDialog
            customersRefetch={customersRefetch}
            triggerSlot={(props: ButtonProps) => (
              <Button className="gap-1" type="button" {...props}>
                <Plus className="h-3.5 w-3.5" />
                Create Customer
              </Button>
            )}
          />
        </div>

        {customersLoading ? (
          <LoadingState />
        ) : customersData?.customers?.length === 0 ? (
          <EmptyState
            title="You have no customers"
            subtitle="Once you create a customer, they will be shown here."
            buttonText="Create Customer"
            button={
              <CreateCustomerDialog
                customersRefetch={customersRefetch}
                triggerSlot={(props: ButtonProps) => (
                  <Button className="mt-4" type="button" {...props}>
                    Create Customer
                    <MoveRight className="ml-2 w-4 h-4" />
                  </Button>
                )}
              />
            }
            buttonRoute="/app/create-customer"
          />
        ) : (
          customersData?.customers && (
            <DataTable
              data={z.array(customerSchema).parse(customersData.customers)}
              columns={customerColumns}
              onRowSelect={(row) => navigate(`/app/customers/${row.original.id}`)}
            />
          )
        )}
      </div>
    </>
  );
};
