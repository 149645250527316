import * as React from 'react';
import { Check, ChevronsUpDown, Plus } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button, ButtonProps } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CUSTOMERS } from '@/graphql/Customers/Customers';
import { useQuery } from '@apollo/client';
import { LoadingState } from '../LoadingState';
import { Customer } from '../Tables/Customers/CustomerSchema';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@radix-ui/react-tooltip';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { CreateCustomerDialog } from './CreateCustomerDialog';

export interface CustomerSelectProps {
  value: string;
  setValue: (val: string) => void;
}

export const CustomerSelect = ({ value, setValue }: CustomerSelectProps) => {
  const [open, setOpen] = React.useState(false);

  const {
    loading: customersLoading,
    data: customersData,
    refetch: customersRefetch
  } = useQuery(CUSTOMERS);

  const getSelectedName = () => {
    const splitValues = value.split('--');

    const customer = customersData?.customers?.find(
      (customer: Customer) => customer.id.toLowerCase() === splitValues[0].toLowerCase()
    );

    return [customer?.firstName, customer?.lastName].join(' ');
  };

  return (
    <div className="flex items-center gap-1">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="w-full">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              disabled={customersLoading}
              className={`flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 justify-between ${
                value ? 'font-normal' : 'font-normal text-muted-foreground'
              }`}
              type="button">
              {customersLoading ? (
                <LoadingState />
              ) : value ? (
                getSelectedName()
              ) : (
                'Select customer...'
              )}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="!w-full p-0">
          <Command className="w-full">
            <CommandInput placeholder="Search for customer..." />
            <CommandEmpty>No customer found.</CommandEmpty>
            <CommandGroup className="max-h-96 overflow-y-auto">
              {customersData?.customers?.map((customer: Customer) => (
                <CommandItem
                  key={customer.id}
                  value={`${customer.id.toLowerCase()}--${customer.firstName.toLowerCase()}--${customer.lastName.toLowerCase()}--${customer.customerUserAccount?.email?.toLowerCase()}`}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="relative flex cursor-default select-none items-center rounded-sm text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 text-left">
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            value.split('--')[0] === customer.id ? 'opacity-100' : 'opacity-0'
                          )}
                        />
                        <div className="flex flex-col">
                          {[customer.firstName, customer.lastName].join(' ')}

                          <div className="text-xs text-muted-foreground">
                            {customer.customerUserAccount?.email}
                          </div>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent side="right" className="bg-muted p-5 ml-2 rounded-md text-sm">
                        <div className="grid gap-3">
                          <div className="font-semibold">Customer Information</div>
                          <dl className="grid gap-1">
                            <div className="flex items-center justify-between gap-5">
                              <dt className="text-muted-foreground">Customer</dt>
                              <dd>{[customer.firstName, customer.lastName].join(' ')}</dd>
                            </div>
                            <div className="flex items-center justify-between gap-5">
                              <dt className="text-muted-foreground">Email</dt>
                              <dd>
                                <a href={`mailto:${customer.customerUserAccount?.email}`}>
                                  {customer.customerUserAccount?.email}
                                </a>
                              </dd>
                            </div>
                            <div className="flex items-center justify-between gap-5">
                              <dt className="text-muted-foreground">Company</dt>
                              <dd>{customer.companyName ? customer.companyName : '-'}</dd>
                            </div>
                            <div className="flex items-center justify-between gap-5">
                              <dt className="text-muted-foreground">Mobile</dt>
                              <dd>
                                <a href={`tel:${customer.mobileNumber}`}>
                                  {customer.mobileNumber
                                    ? isValidPhoneNumber(customer.mobileNumber)
                                      ? formatPhoneNumberIntl(customer.mobileNumber)
                                      : customer.mobileNumber
                                    : '-'}
                                </a>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <CreateCustomerDialog
        customersRefetch={customersRefetch}
        triggerSlot={(props: ButtonProps) => {
          return (
            <Button size="icon" variant="ghost" type="button" {...props}>
              <Plus className="w-3.5 h-3.5" />
            </Button>
          );
        }}
        onCreate={(customer: Customer) =>
          setValue(
            `${customer.id.toLowerCase()}--${customer.firstName.toLowerCase()}--${customer.lastName.toLowerCase()}--${customer.customerUserAccount?.email?.toLowerCase()}`
          )
        }
      />
    </div>
  );
};
