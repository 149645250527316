import { gql } from '@apollo/client';

export const DISPUTES = gql`
  query Disputes {
    disputes {
      id
      status
      createdAt
      updatedAt
      transaction {
        id
        title
        status
        completeDays
        amount
        description
        createdAt
        updatedAt
      }
    }
  }
`;
