import { gql } from '@apollo/client';

export const CUSTOMER = gql`
  query Customer($id: ID!) {
    customer(id: $id) {
      id
      customerUserAccount {
        id
        email
      }
      merchantUserAccount {
        id
      }
      createdAt
      updatedAt
      firstName
      lastName
      companyName
      mobileNumber
    }
  }
`;
