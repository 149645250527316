import { ReactNode } from 'react';

export interface HeaderProps {
  title: string | ReactNode;
  actionSlot?: ReactNode;
}

export const Header = ({ title, actionSlot }: HeaderProps) => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-lg font-semibold md:text-2xl">{title}</h1>
      {actionSlot}
    </div>
  );
};
