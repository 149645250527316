import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '../../../ui/badge';
import { DataTableColumnHeader } from '../../../ui/data-table-column-header';
import { CalendarDays, CalendarPlus, CircleDollarSign, CreditCard, Wallet } from 'lucide-react';
import { formatDateTime, formatDollarFromCents } from '@/utils';
import { Transaction } from './TransactionSchema';
import { transactionStatuses } from './TransactionData';
import { TransactionType } from '@/enums/TransactionType';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

export const transactionColumns: ColumnDef<Transaction>[] = [
  {
    accessorKey: 'transactionRole',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
    cell: ({ row }) => {
      return (
        <div className="w-6 flex items-center justify-center">
          {row.original.transactionRole === TransactionType.SELLER ? (
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger>
                  <Wallet className="w-4 h-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent side="right">
                  <p>Seller</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger>
                  <CreditCard className="w-4 h-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent side="right">
                  <p>Buyer</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.original.transactionRole);
    }
  },
  {
    accessorKey: 'transaction',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Transaction" />,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">{row.original.title}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      console.log(value);
      return (
        value.includes(row.original.status) ||
        value.includes(row.original.status?.replaceAll('_', ' ').toLowerCase()) ||
        row.original.title?.toLowerCase().includes(value?.toLowerCase() ?? '')
      );
    }
  },
  {
    accessorKey: 'buyer',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" />,
    cell: ({ row }) => {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <div className="flex flex-col text-left">
                <div className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                  {row.original.buyer?.firstName} {row.original.buyer?.lastName}
                </div>
                <div className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                  {row.original.buyer?.companyName}
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">
              <div className="grid gap-3">
                <div className="font-semibold">Customer Information</div>
                <dl className="grid gap-3">
                  <div className="flex items-center justify-between gap-5">
                    <dt className="text-muted-foreground">Customer</dt>
                    <dd>
                      {[row.original.buyer?.firstName, row.original.buyer?.lastName].join(' ')}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <dt className="text-muted-foreground">Email</dt>
                    <dd>
                      <a href={`mailto:${row.original.buyer?.customerUserAccount?.email}`}>
                        {row.original.buyer?.customerUserAccount?.email}
                      </a>
                    </dd>
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <dt className="text-muted-foreground">Company</dt>
                    <dd>
                      {row.original.buyer?.companyName ? row.original.buyer?.companyName : '-'}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <dt className="text-muted-foreground">Mobile</dt>
                    <dd>
                      <a href={`tel:${row.original.buyer?.mobileNumber}`}>
                        {row.original.buyer?.mobileNumber
                          ? isValidPhoneNumber(row.original.buyer?.mobileNumber)
                            ? formatPhoneNumberIntl(row.original.buyer?.mobileNumber)
                            : row.original.buyer?.mobileNumber
                          : '-'}
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
  },
  {
    accessorKey: 'completeIn',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Complete In" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[130px] items-center">
          <CalendarDays className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{row.original.completeDays} days</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Amount" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[130px] items-center">
          <CircleDollarSign className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{formatDollarFromCents(row.original.amount)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => {
      const transactionStatusData = transactionStatuses.find(
        (transactionStatus) => transactionStatus.value === row.original.status
      );

      return (
        <div className="w-[130px]">
          <Badge
            variant={transactionStatusData?.destructive ? 'destructive' : 'outline'}
            className="capitalize flex gap-1 items-center w-min whitespace-nowrap">
            {transactionStatusData && <transactionStatusData.icon className="w-3 h-3" />}
            {row.original.status?.replaceAll('_', ' ').toLowerCase()}
          </Badge>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.status);
    }
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[200px] items-center">
          <CalendarPlus className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{formatDateTime(row.original.createdAt)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  }
];
