import { useState } from 'react';
import worldImage from '@/assets/world.png';
import { useNavigate } from 'react-router-dom';

export const JoinOthers = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');

  return (
    <div className="py-16 bg-blue-50 text-gray-900">
      <div className="mx-auto max-w-6xl grid grid-cols-2 items-center justify-center gap-40 relative">
        <div className="flex flex-col gap-6 z-50">
          <div className="text-3xl font-semibold">
            Join{' '}
            <span className="bg-gradient-to-r from-cyan-500 to-primary text-transparent bg-clip-text">
              140,000+
            </span>{' '}
            other users and start earning!
          </div>

          <div className="flex max-w-md w-full items-center bg-white rounded-lg">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email address"
              className="flex-1 h-14 p-3 text-black focus:outline-none bg-white rounded-lg"
            />
            <button
              className="h-12 mr-1 px-8 bg-primary hover:bg-primary/80 transition ease-in-out text-lg text-white rounded-lg"
              onClick={() => navigate(`/signup/${email}`)}>
              Get started
            </button>
          </div>
        </div>

        <div>
          <img src={worldImage} />
        </div>
      </div>
    </div>
  );
};
