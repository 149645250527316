import { LoadingState } from '@/components/App/LoadingState';
import { Logo } from '@/components/Common/Logo';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { auth } from '@/firebase';
import { zodResolver } from '@hookform/resolvers/zod';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        navigate('/app');
      } else {
        setIsLoading(false);
      }
    });
  });

  const formSchema = z.object({
    email: z
      .string()
      .min(3, {
        message: 'Email must be at least 3 characters'
      })
      .max(80, {
        message: 'Email must be less than 80 characters'
      })
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await sendPasswordResetEmail(auth, values.email);
      setIsEmailSent(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e.code);
      if (e.code === 'auth/invalid-credential') {
        toast.error('Your email or password was incorrect.');
      } else if (e.code === 'auth/invalid-email') {
        toast.error('Please provide a valid email address.');
      } else if (e.code === 'auth/missing-password') {
        toast.error('Please provide a password.');
      } else {
        toast.error('There was a problem signing in with those credentials, please try again.');
      }
    }
  };

  return isLoading ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingState />
    </div>
  ) : (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full max-w-md space-y-8">
        <Logo className="h-6 w-auto ml-5" />
        {isEmailSent ? (
          <Card className="mx-auto p-8">
            <CardHeader>
              <CardTitle className="text-xl">Reset your password</CardTitle>
              <CardDescription></CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mx-auto grid gap-6 w-full">
                Your password reset email has been sent successfully.
              </div>
              <div className="mt-4 text-center text-sm">
                <a className="underline cursor-pointer" onClick={() => navigate('/signin')}>
                  Return to sign in
                </a>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
              <Card className="mx-auto p-8">
                <CardHeader>
                  <CardTitle className="text-xl">Reset your password</CardTitle>
                  <CardDescription></CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="mx-auto grid gap-6 w-full">
                    <div className="grid gap-5">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="max@gatedpay.com" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <Button type="submit" className="w-full">
                        Continue
                      </Button>
                    </div>
                    <div className="mt-4 text-center text-sm">
                      <a className="underline cursor-pointer" onClick={() => navigate('/signin')}>
                        Return to sign in
                      </a>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
};
