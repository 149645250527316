import { CircleUser, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { MobileNavigation, WebNavigation } from '@/components/App/Navigation';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CURRENT_USER_ACCOUNT } from '@/graphql/UserAccount/CurrentUserAccount';
import { useQuery } from '@apollo/client';
import { useUser } from '@/context/userContext';
import { LoadingState } from '@/components/App/LoadingState';
import { auth } from '@/firebase';
import { signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { STRIPE_ACCOUNT_IS_SETUP } from '@/graphql/UserAccount/StripeAccountIsSetup';

export const AppLayout = () => {
  const { pathname } = useLocation();
  const { dispatch: userDispatch, state: userState } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const animate = !pathname.includes('/app/settings');

  const { loading: currentUserAccountLoading } = useQuery(CURRENT_USER_ACCOUNT, {
    fetchPolicy: 'no-cache',
    skip: isLoading || auth.currentUser === null,
    onCompleted(data) {
      userDispatch({
        type: 'SET_USER_ACCOUNT',
        userAccount: data.currentUserAccount
      });
    }
  });

  const { loading: stripeStatusLoading } = useQuery(STRIPE_ACCOUNT_IS_SETUP, {
    fetchPolicy: 'no-cache',
    skip: isLoading || auth.currentUser === null || !userState.userAccount,
    onCompleted(data) {
      userDispatch({
        type: 'SET_STRIPE_STATUS',
        isSetup: data.stripeAccountIsSetup
      });
    }
  });

  useEffect(() => {
    auth.onAuthStateChanged(() => setIsLoading(false));
  }, [setIsLoading]);

  const loading = currentUserAccountLoading || isLoading || stripeStatusLoading;

  if (!isLoading && auth.currentUser === null) {
    navigate('/signin');
  }

  const logoutUser = async () => {
    await signOut(auth);
    navigate('/');
  };

  const pageVariants = {
    initial: {
      opacity: animate ? 0 : 1
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: animate ? 0 : 1
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: animate ? 0.2 : 0
  };

  return loading ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingState />
    </div>
  ) : (
    <div className="grid h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr] overflow-hidden">
      <WebNavigation />
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-2 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
          <MobileNavigation />
          <div className="w-full flex-1">
            <form>
              <div className="relative">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search customers, transactions, disputes, payouts..."
                  className="w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3"
                />
              </div>
            </form>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="gap-2">
                {[userState.userAccount?.firstName, userState.userAccount?.lastName].join(' ')}
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => navigate('/app/settings')}>
                Account Settings
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate('/app/settings/verification')}>
                Verification
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate('/app/settings/appearance')}>
                Appearance
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate('/app/settings/notifications')}>
                Notifications
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => logoutUser()}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </header>

        <motion.div
          key={pathname}
          initial="initial"
          animate="in"
          variants={pageVariants}
          transition={pageTransition}>
          <div className="h-[calc(100vh-60px)] overflow-auto">
            <Outlet />
          </div>
        </motion.div>
      </div>
    </div>
  );
};
