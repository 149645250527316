import { Header } from '@/components/App/Header';
import { MoveLeft, Plus } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { CUSTOMER } from '@/graphql/Customers/Customer';
import { LoadingState } from '@/components/App/LoadingState';
import { customerSchema } from '@/components/App/Tables/Customers/CustomerSchema';
import { useNavigate, useParams } from 'react-router-dom';
import { CreditCard, MoreVertical } from 'lucide-react';
import { formatDate } from '@/utils';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { EditCustomerDialog } from '@/components/App/Customer/EditCustomerDialog';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

export const Customer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading: customerLoading,
    data: customerData,
    refetch: customerRefetch
  } = useQuery(CUSTOMER, {
    variables: {
      id
    }
  });

  const userData = !customerLoading ? customerSchema.parse(customerData?.customer) : undefined;

  return (
    <>
      <Helmet>
        <title>
          {userData ? `${[userData?.firstName, userData?.lastName].join(' ')} | ` : ''}Customer |
          Gated Pay
        </title>
      </Helmet>
      <div className="main-div">
        {customerLoading ? (
          <LoadingState />
        ) : (
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <div
                  className="flex items-center gap-1 hover:text-primary text-xs cursor-pointer text-muted-foreground"
                  onClick={() => navigate('/app/customers')}>
                  <MoveLeft className="w-4 h-4" />
                  Back to Customers
                </div>
                <Header title={`${[userData?.firstName, userData?.lastName].join(' ')}`} />
                <div className="text-muted-foreground">{userData?.customerUserAccount?.email}</div>
              </div>

              <div className="flex items-center gap-3">
                <Button
                  className="gap-1"
                  onClick={() => navigate(`/app/create-transaction/${userData?.id}`)}>
                  <Plus className="h-3.5 w-3.5" />
                  Create Transaction
                </Button>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button size="icon" variant="outline">
                      <MoreVertical className="h-3.5 w-3.5" />
                      <span className="sr-only">More</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem>Send email</DropdownMenuItem>
                    <DropdownMenuItem>Call mobile</DropdownMenuItem>
                    <DropdownMenuItem>Export transactions</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Report to Gated Pay</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem className="text-red-500 hover:!text-red-600">
                      Archive account
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-10">
              <div className="col-span-4">
                <Card className="overflow-hidden">
                  <CardHeader className="flex flex-row items-center bg-muted/50">
                    <CardTitle className="text-lg">Details</CardTitle>

                    <div className="ml-auto flex items-center gap-1">
                      <EditCustomerDialog customer={userData} customerRefetch={customerRefetch} />
                    </div>
                  </CardHeader>
                  <CardContent className="p-6 text-sm">
                    <div className="grid gap-3">
                      <div className="font-semibold">Customer Information</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Customer</dt>
                          <dd>{[userData?.firstName, userData?.lastName].join(' ')}</dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Email</dt>
                          <dd>
                            <a href={`mailto:${userData?.customerUserAccount?.email}`}>
                              {userData?.customerUserAccount?.email}
                            </a>
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Company</dt>
                          <dd>{userData?.companyName ? userData?.companyName : '-'}</dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Mobile</dt>
                          <dd>
                            <a href={`tel:${userData?.mobileNumber}`}>
                              {userData?.mobileNumber
                                ? isValidPhoneNumber(userData?.mobileNumber)
                                  ? formatPhoneNumberIntl(userData?.mobileNumber)
                                  : userData?.mobileNumber
                                : '-'}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <Separator className="my-4" />
                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-3">
                        <div className="font-semibold">Shipping Information</div>
                        <address className="grid gap-0.5 not-italic text-muted-foreground">
                          <span>{[userData?.firstName, userData?.lastName].join(' ')}</span>
                          <span>1234 Main St.</span>
                          <span>Anytown, CA 12345</span>
                        </address>
                      </div>
                      <div className="grid auto-rows-max gap-3 text-right">
                        <div className="font-semibold">Billing Information</div>
                        <div className="text-muted-foreground">Same as shipping address</div>
                      </div>
                    </div>

                    <Separator className="my-4" />
                    <div className="grid gap-3">
                      <div className="font-semibold">Payment Information</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="flex items-center gap-1 text-muted-foreground">
                            <CreditCard className="h-4 w-4" />
                            Visa
                          </dt>
                          <dd>**** **** **** 4532</dd>
                        </div>
                      </dl>
                    </div>
                  </CardContent>
                  <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                    <div className="text-xs text-muted-foreground">
                      Last updated{' '}
                      <time dateTime="2023-11-23">{formatDate(userData?.updatedAt)}</time>
                    </div>
                  </CardFooter>
                </Card>
              </div>

              <div className="col-span-8 space-y-10">
                <div className="flex flex-col gap-5">
                  <div className="flex items-center">
                    <CardTitle className="text-lg">Transactions</CardTitle>
                  </div>

                  <div className="border-2 border-dashed rounded-lg p-14 flex items-center justify-center text-muted-foreground text-sm">
                    No transactions
                  </div>
                </div>

                <div className="flex flex-col gap-5">
                  <div className="flex items-center">
                    <CardTitle className="text-lg">Payments</CardTitle>
                  </div>

                  <div className="border-2 border-dashed rounded-lg p-14 flex items-center justify-center text-muted-foreground text-sm">
                    No payments
                  </div>
                </div>

                <div className="flex flex-col gap-5">
                  <div className="flex items-center">
                    <CardTitle className="text-lg">Disputes</CardTitle>
                  </div>

                  <div className="border-2 border-dashed rounded-lg p-14 flex items-center justify-center text-muted-foreground text-sm">
                    No disputes
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
