import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthorizedApolloProvider } from './apollo/AuthorizedApolloProvider';
import { Toaster } from '@/components/ui/sonner';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './context/userContext';
import { ThemeProvider } from './context/themeContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthorizedApolloProvider>
      <UserProvider>
        <ThemeProvider defaultTheme="system" storageKey="gated-pay-theme">
          <App />
          <Toaster position="top-right" richColors />
        </ThemeProvider>
      </UserProvider>
    </AuthorizedApolloProvider>
  </BrowserRouter>
);
