import { z } from 'zod';

export const transactionPaymentSchema = z.object({
  id: z.string(),
  netPayment: z.number().optional(),
  paymentStatus: z
    .enum(['NOT_STARTED', 'PENDING_PAYMENT_CONFIRMATION', 'PAYMENT_COMPLETE', 'PAYMENT_DECLINED'])
    .optional(),
  paymentId: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  currency: z.string().optional(),
  paymentAt: z.string().nullable().optional()
});

export type Transaction = z.infer<typeof transactionPaymentSchema>;
