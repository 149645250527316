import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

export interface EmptyStateProps {
  title: string;
  subtitle: string;
  buttonRoute: string;
  buttonText?: string;
  button?: ReactNode;
  buttonIcon?: ReactNode;
}

export const EmptyState = ({
  title,
  subtitle,
  buttonIcon,
  buttonRoute,
  buttonText,
  button
}: EmptyStateProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm h-full">
      <div className="flex flex-col items-center gap-1 text-center">
        <h3 className="text-2xl font-bold tracking-tight">{title}</h3>
        <p className="text-sm text-muted-foreground">{subtitle}</p>
        {button ? (
          button
        ) : (
          <Button className="mt-4" onClick={() => navigate(buttonRoute)}>
            {buttonText}
            {buttonIcon}
          </Button>
        )}
      </div>
    </div>
  );
};
