import { buttonVariants } from '@/components/ui/button';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

const sidebarNavItems = [
  {
    title: 'Account',
    href: '/app/settings'
  },
  {
    title: 'Verification',
    href: '/app/settings/verification'
  },
  {
    title: 'Appearance',
    href: '/app/settings/appearance'
  },
  {
    title: 'Notifications',
    href: '/app/settings/notifications'
  }
];

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.2
};

export const SettingsLayout = () => {
  const { pathname } = useLocation();
  return (
    <div className="main-div">
      <div className="hidden space-y-6 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <nav className={cn('flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1')}>
              {sidebarNavItems.map((item) => (
                <Link
                  key={item.href}
                  to={item.href}
                  className={cn(
                    buttonVariants({ variant: 'ghost' }),
                    pathname === item.href
                      ? 'bg-muted hover:bg-muted'
                      : 'hover:bg-transparent hover:underline',
                    'justify-start'
                  )}>
                  {item.title}
                </Link>
              ))}
            </nav>
          </aside>

          <motion.div
            key={pathname}
            initial="initial"
            animate="in"
            variants={pageVariants}
            transition={pageTransition}>
            <div className="flex-1 lg:max-w-2xl">
              <Outlet />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
