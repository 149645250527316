import { z } from 'zod';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const userAccountSchema = z.object({
  id: z.string(),
  userId: z.string().optional(),
  systemRole: z.enum(['USER', 'SUPPORT', 'SUPERUSER']).optional(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  companyName: z.string().optional(),
  mobileNumber: z.string().optional(),
  stripeCustomerId: z.string().optional(),
  countryCode: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  mobileVerified: z.boolean().optional(),
  documentVerified: z.boolean().optional()
});

export type UserAccount = z.infer<typeof userAccountSchema>;
