import { z } from 'zod';
import { userAccountSchema } from '../UserAccounts/UserAccountSchema';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const customerSchema = z.object({
  id: z.string(),
  customerUserAccount: userAccountSchema.optional(),
  merchantUserAccount: userAccountSchema.optional(),
  firstName: z.string(),
  lastName: z.string(),
  companyName: z.string().optional(),
  mobileNumber: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});

export type Customer = z.infer<typeof customerSchema>;
