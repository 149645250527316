import { TransactionStatus } from '@/enums/TransactionStatus';
import { TransactionType } from '@/enums/TransactionType';
import {
  CheckCheck,
  CircleEllipsis,
  CreditCard,
  FileInput,
  FileX,
  NotepadTextDashed,
  ShieldAlert,
  UserCheck,
  UserCog,
  UserX,
  Wallet
} from 'lucide-react';

export const transactionTypes = [
  {
    value: TransactionType.SELLER,
    label: 'Seller',
    icon: Wallet
  },
  {
    value: TransactionType.BUYER,
    label: 'Buyer',
    icon: CreditCard
  }
];

export const transactionStatuses = [
  {
    value: TransactionStatus.DRAFT,
    label: 'Draft',
    icon: NotepadTextDashed
  },
  {
    value: TransactionStatus.PENDING_SCOPE_APPROVAL,
    label: 'Pending Scope Approval',
    icon: FileInput
  },
  {
    value: TransactionStatus.SCOPE_DENIED,
    label: 'Scope Denied',
    icon: FileX,
    destructive: true
  },
  {
    value: TransactionStatus.IN_PROGRESS,
    label: 'In Progress',
    icon: CircleEllipsis
  },
  {
    value: TransactionStatus.PENDING_COMPLETION_CONFIRMATION,
    label: 'Pending Completion Confirmation',
    icon: UserCog
  },
  {
    value: TransactionStatus.CONFIRMED_COMPLETED,
    label: 'Confirmed Completed',
    icon: UserCheck
  },
  {
    value: TransactionStatus.COMPLETION_DENIED,
    label: 'Completion Denied',
    icon: UserX,
    destructive: true
  },
  {
    value: TransactionStatus.UNDER_DISPUTE,
    label: 'Under Dispute',
    icon: ShieldAlert,
    destructive: true
  },
  {
    value: TransactionStatus.COMPLETED,
    label: 'Completed',
    icon: CheckCheck
  }
];
