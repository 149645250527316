import { Header } from '@/components/App/Header';
import {
  ArrowLeft,
  BadgeCheck,
  CircleCheck,
  CreditCard,
  MoreVertical,
  Send,
  X
} from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LoadingState } from '@/components/App/LoadingState';
import { TRANSACTION } from '@/graphql/Transaction/Transaction';
import { useNavigate, useParams } from 'react-router-dom';
import { transactionSchema } from '@/components/App/Tables/Transactions/TransactionSchema';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card';
import { formatDate, formatDateTime, formatDollarFromCents } from '@/utils';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Badge } from '@/components/ui/badge';
import { transactionStatuses } from '@/components/App/Tables/Transactions/TransactionData';
import { TransactionType } from '@/enums/TransactionType';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import * as DOMPurify from 'dompurify';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import bulletListIcon from '@/assets/bullet_list.svg';
import numberListIcon from '@/assets/number_list.svg';
import indentIncreaseIcon from '@/assets/indent_increase.svg';
import indentDecreaseIcon from '@/assets/indent_decrease.svg';
import boldIcon from '@/assets/bold.svg';
import italicIcon from '@/assets/italic.svg';
import underlineIcon from '@/assets/underline.svg';
import strikethroughIcon from '@/assets/strikethrough.svg';
import { TransactionStatus } from '@/enums/TransactionStatus';
import { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UPDATE_TRANSACTION_DESCRIPTION } from '@/graphql/Transaction/UpdateTransactionDescription';
import { SEND_TRANSACTION_TO_CUSTOMER } from '@/graphql/Transaction/SendTransactionToCustomer';
import { useUser } from '@/context/userContext';
import { CHECKOUT_SESSION_URL } from '@/graphql/Transaction/CheckoutSessionForTransaction';
import moment from 'moment';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { DENY_SCOPE } from '@/graphql/Transaction/DenyTransaction';

export const Transaction = () => {
  const { id } = useParams();
  const { state: userState } = useUser();
  const navigate = useNavigate();
  const [scopeEditorState, setScopeEditorState] = useState<EditorState>(EditorState.createEmpty());

  const {
    loading: transactionLoading,
    data: transactionData,
    refetch: transactionRefetch
  } = useQuery(TRANSACTION, {
    variables: {
      id
    },
    onCompleted(data) {
      setScopeEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.transaction.description).contentBlocks
          )
        )
      );

      form.setValue('description', data.transaction.description);
    }
  });

  const [fetchCheckoutSessionUrl, { loading: fetchCheckoutSessionUrlLoading }] =
    useLazyQuery(CHECKOUT_SESSION_URL);

  const [updateTransactionDescription, { loading: updateDescriptionLoading }] = useMutation(
    UPDATE_TRANSACTION_DESCRIPTION
  );

  const [denyScope, { loading: denyingScope }] = useMutation(DENY_SCOPE);

  const [sendTransactionToCustomer, { loading: sendTransactionToCustomerLoading }] = useMutation(
    SEND_TRANSACTION_TO_CUSTOMER
  );

  const transaction = !transactionLoading
    ? transactionSchema.parse(transactionData?.transaction)
    : undefined;

  const transactionStatusData = transactionStatuses.find(
    (transactionStatus) => transactionStatus.value === transaction?.status
  );

  const formSchema = z.object({
    description: z.string().min(9, {
      message: 'Description is required'
    })
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      description: transaction?.description ?? ''
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateTransactionDescription({
      variables: {
        id: transaction?.id,
        description: values.description
      }
    });

    transactionRefetch();
  };

  const isBuyer = transaction?.buyer?.customerUserAccount?.id === userState.userAccount?.id;
  const isSeller = transaction?.seller?.id === userState.userAccount?.id;

  return (
    <>
      <Helmet>
        <title>Transaction | Gated Pay</title>
      </Helmet>
      <div className="main-div">
        {transactionLoading ? (
          <LoadingState />
        ) : (
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <Header
                  title={
                    <div
                      className="flex items-center gap-2 group -ml-7 cursor-pointer"
                      onClick={() => navigate('/app/transactions')}>
                      <ArrowLeft className="opacity-0 group-hover:opacity-100 w-5 h-5 transition ease-in-out" />
                      <Badge
                        variant={transactionStatusData?.destructive ? 'destructive' : 'outline'}
                        className="capitalize flex gap-1 items-center w-min whitespace-nowrap text-base">
                        {transactionStatusData && (
                          <transactionStatusData.icon className="w-4 h-4" />
                        )}
                        {transaction?.status?.replaceAll('_', ' ').toLowerCase()}
                      </Badge>
                      <span className="group-hover:underline">{transaction?.title}</span>
                    </div>
                  }
                />
                <div className="text-muted-foreground">
                  You&apos;re the {transaction?.transactionRole.toLowerCase()}
                </div>
              </div>

              <div className="flex items-center gap-3">
                {(transaction?.status === TransactionStatus.DRAFT ||
                  transaction?.status === TransactionStatus.SCOPE_DENIED) &&
                  isSeller && (
                    <Button
                      className="gap-1"
                      disabled={sendTransactionToCustomerLoading}
                      onClick={async () => {
                        await sendTransactionToCustomer({
                          variables: {
                            id: transaction.id
                          }
                        });

                        transactionRefetch();
                      }}>
                      <Send className="h-3.5 w-3.5" />
                      Send to Customer
                    </Button>
                  )}
                {transaction?.status === TransactionStatus.PENDING_SCOPE_APPROVAL && isBuyer && (
                  <Button
                    className="gap-1"
                    disabled={denyingScope}
                    variant="outline"
                    onClick={async () => {
                      await denyScope({
                        variables: {
                          id: transaction.id,
                          message: ''
                        }
                      });

                      transactionRefetch();
                    }}>
                    <X className="h-3.5 w-3.5 text-red-500" />
                    Decline Scope
                  </Button>
                )}
                {transaction?.status === TransactionStatus.PENDING_SCOPE_APPROVAL && isBuyer && (
                  <Button
                    className="gap-1"
                    disabled={fetchCheckoutSessionUrlLoading}
                    onClick={async () => {
                      const res = await fetchCheckoutSessionUrl({
                        variables: {
                          transactionId: transaction.id
                        }
                      });

                      window.open(res.data.checkoutSessionForTransaction);
                    }}>
                    <CreditCard className="h-3.5 w-3.5" />
                    Accept & Pay
                  </Button>
                )}
                {transaction?.status === TransactionStatus.IN_PROGRESS && isSeller && (
                  <Button
                    className="gap-1"
                    disabled={sendTransactionToCustomerLoading}
                    onClick={async () => {
                      const res = await fetchCheckoutSessionUrl({
                        variables: {
                          transactionId: transaction.id
                        }
                      });

                      window.open(res.data.checkoutSessionForTransaction);
                    }}>
                    <CircleCheck className="h-3.5 w-3.5" />
                    Complete Transaction
                  </Button>
                )}
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button size="icon" variant="outline">
                      <MoreVertical className="h-3.5 w-3.5" />
                      <span className="sr-only">More</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem>Send email</DropdownMenuItem>
                    <DropdownMenuItem>Call mobile</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Report to Gated Pay</DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem className="text-red-500 hover:!text-red-600">
                      Archive account
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-10">
              <div className="col-span-4">
                <Card className="overflow-hidden">
                  <CardHeader className="flex flex-row items-center bg-muted/50">
                    <CardTitle className="text-lg">Details</CardTitle>
                  </CardHeader>
                  <CardContent className="p-6 text-sm">
                    <div className="grid gap-3">
                      <div className="font-semibold">Transaction Details</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Amount</dt>
                          <dd>{formatDollarFromCents(transaction?.amount)}</dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Days Required</dt>
                          <dd>{transaction?.completeDays} days</dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Complete By</dt>
                          {transaction?.transactionPayment?.paymentAt ? (
                            <dd>
                              {formatDateTime(
                                moment(transaction.transactionPayment.paymentAt).add(
                                  transaction.completeDays,
                                  'days'
                                )
                              )}
                            </dd>
                          ) : (
                            <dd className="text-muted-foreground">Pending Payment</dd>
                          )}
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Status</dt>
                          <dd>
                            <Badge
                              variant={
                                transactionStatusData?.destructive ? 'destructive' : 'outline'
                              }
                              className="capitalize flex gap-1 items-center w-min whitespace-nowrap">
                              {transactionStatusData && (
                                <transactionStatusData.icon className="w-3 h-3" />
                              )}
                              {transaction?.status?.replaceAll('_', ' ').toLowerCase()}
                            </Badge>
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <Separator className="my-4" />

                    <div className="grid gap-3">
                      <div className="font-semibold">Payment Details</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Payment Status</dt>
                          <dd>
                            <Badge
                              variant="outline"
                              className="capitalize flex gap-1 items-center w-min whitespace-nowrap">
                              {transaction?.transactionPayment?.paymentStatus
                                ?.replaceAll('_', ' ')
                                .toLowerCase() ?? 'Not Started'}
                            </Badge>
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Payment ID</dt>
                          <dd>
                            <div className="flex items-center gap-1">
                              {transaction?.transactionPayment?.paymentId && (
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <BadgeCheck className="text-primary w-4 h-4" />
                                    </TooltipTrigger>
                                    <TooltipContent side="left">
                                      <p>Payment verified</p>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              )}
                              {transaction?.transactionPayment?.paymentId ?? '-'}
                            </div>
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Payment At</dt>
                          <dd>
                            {transaction?.transactionPayment?.paymentAt
                              ? formatDateTime(transaction?.transactionPayment?.paymentAt)
                              : '-'}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <Separator className="my-4" />

                    <div className="grid gap-3">
                      <div className="font-semibold">
                        Buyer{transaction?.transactionRole === TransactionType.BUYER ? '/Your' : ''}{' '}
                        Information
                      </div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Name</dt>
                          <dd>
                            {[transaction?.buyer?.firstName, transaction?.buyer?.lastName].join(
                              ' '
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Email</dt>
                          <dd>
                            <a href={`mailto:${transaction?.buyer?.customerUserAccount?.email}`}>
                              {transaction?.buyer?.customerUserAccount?.email}
                            </a>
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Company</dt>
                          <dd>
                            {transaction?.buyer?.companyName
                              ? transaction?.buyer?.companyName
                              : '-'}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Mobile</dt>
                          <dd>
                            <a href={`tel:${transaction?.buyer?.mobileNumber}`}>
                              {transaction?.buyer?.mobileNumber
                                ? isValidPhoneNumber(transaction?.buyer?.mobileNumber)
                                  ? formatPhoneNumberIntl(transaction?.buyer?.mobileNumber)
                                  : transaction?.buyer?.mobileNumber
                                : '-'}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <Separator className="my-4" />

                    <div className="grid gap-3">
                      <div className="font-semibold">
                        Seller
                        {transaction?.transactionRole === TransactionType.SELLER
                          ? '/Your'
                          : ''}{' '}
                        Information
                      </div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Name</dt>
                          <dd>
                            {[transaction?.seller?.firstName, transaction?.seller?.lastName].join(
                              ' '
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Email</dt>
                          <dd>
                            <a href={`mailto:${transaction?.seller?.email}`}>
                              {transaction?.seller?.email}
                            </a>
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Company</dt>
                          <dd>
                            {transaction?.seller?.companyName
                              ? transaction?.seller?.companyName
                              : '-'}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Mobile</dt>
                          <dd>
                            <a href={`tel:${transaction?.seller?.mobileNumber}`}>
                              {transaction?.seller?.mobileNumber
                                ? isValidPhoneNumber(transaction?.seller?.mobileNumber)
                                  ? formatPhoneNumberIntl(transaction?.seller?.mobileNumber)
                                  : transaction?.seller?.mobileNumber
                                : '-'}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </CardContent>
                  <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                    <div className="text-xs text-muted-foreground">
                      Last updated{' '}
                      <time dateTime="2023-11-23">{formatDate(transaction?.updatedAt)}</time>
                    </div>
                  </CardFooter>
                </Card>
              </div>

              <div className="col-span-8 space-y-10">
                <Tabs defaultValue="scope">
                  <TabsList>
                    <TabsTrigger value="scope">Scope</TabsTrigger>
                    <TabsTrigger value="messages">Messages</TabsTrigger>
                    <TabsTrigger value="documents">Documents</TabsTrigger>
                  </TabsList>
                  <TabsContent value="scope">
                    <Card className="overflow-hidden">
                      <CardHeader className="flex flex-row items-start bg-muted/50">
                        <div className="grid gap-0.5">
                          <CardTitle className="group flex items-center gap-2 text-lg">
                            Scope
                          </CardTitle>
                        </div>
                      </CardHeader>
                      <CardContent className="p-6 text-sm description">
                        {(transaction?.status !== TransactionStatus.DRAFT &&
                          transaction?.status !== TransactionStatus.SCOPE_DENIED &&
                          isSeller) ||
                        isBuyer ? (
                          <div
                            className="space-y-3"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(transaction?.description ?? '', {
                                FORBID_TAGS: ['style'],
                                FORBID_ATTR: ['style']
                              })
                            }}></div>
                        ) : (
                          <Form {...form}>
                            <form
                              className="space-y-5"
                              onSubmit={form.handleSubmit(onSubmit)}
                              autoComplete="off">
                              <FormField
                                control={form.control}
                                name="description"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <Editor
                                        placeholder="Detailed description of goods/services"
                                        toolbar={{
                                          options: ['inline', 'list'],
                                          inline: {
                                            options: [
                                              'bold',
                                              'italic',
                                              'underline',
                                              'strikethrough'
                                            ],
                                            bold: { icon: boldIcon },
                                            italic: { icon: italicIcon },
                                            underline: { icon: underlineIcon },
                                            strikethrough: { icon: strikethroughIcon }
                                          },
                                          list: {
                                            unordered: { icon: bulletListIcon },
                                            ordered: { icon: numberListIcon },
                                            indent: { icon: indentIncreaseIcon },
                                            outdent: { icon: indentDecreaseIcon }
                                          }
                                        }}
                                        editorState={scopeEditorState}
                                        onEditorStateChange={setScopeEditorState}
                                        onChange={(e) => field.onChange(draftToHtml(e))}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />

                              <div className="flex items-center justify-end">
                                <Button
                                  type="submit"
                                  disabled={
                                    transaction?.description === form.getValues().description ||
                                    updateDescriptionLoading
                                  }>
                                  Save
                                </Button>
                              </div>
                            </form>
                          </Form>
                        )}
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="messages">
                    <Card className="overflow-hidden">
                      <CardHeader className="flex flex-row items-start bg-muted/50">
                        <div className="grid gap-0.5">
                          <CardTitle className="group flex items-center gap-2 text-lg">
                            Messages
                          </CardTitle>
                        </div>
                      </CardHeader>
                      <CardContent className="p-6 text-sm">{transaction?.description}</CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
